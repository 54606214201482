import {
  Checkbox,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps
} from '@mui/material';
import { iconFromComponentStatus } from '../StatusIcon';
import { useState } from 'react';
import MDIcon from '@mdi/react';
import { mdiMenuRightOutline, mdiMenuDown } from '@mdi/js';
import styled from 'styled-components';
import ServiceTile from './ServiceTile';
import { IComponentGroup, IIncident } from '../../interfaces';
import SectionHeader from '../SectionHeader';

interface IComponentView {
  components: Array<IComponentGroup>;
  scheduledMaintenances: Array<IIncident>;
}

export const ServiceTileArrow = styled.div`
  flex-grow: 1;
  background-color: #ffffff1a;
`;

const CenterItem = styled.div`
  margin: auto auto;
  text-align: center;
  flex-grow: 1;
`;

function ListItem(props: {
  id: string;
  children: any;
  level: number;
  service_tile?: number;
  subgroups?: Array<any>;
  components?: Array<any>;
  status?: number;
  isComponent?: boolean;
  onCheckFunction?: (id: string, checked: boolean) => void;
  checkedFunction?: (id: string) => boolean;
  showSubgroups: boolean;
}) {
  const [expand, setExpand] = useState(false);

  const isExpandable = () => {
    return props.subgroups?.length || props.components?.length;
  };

  const sx: SxProps =
    props.level === 0
      ? {
          padding: '0px !important',
          background: '#555555 !important',
          boxshadow:
            '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2)'
        }
      : { background: '#00000033 !important', marginTop: '0px' };

  let listItemButton;

  // IS a top level goup
  if (props.level === 0)
    listItemButton = (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="strech"
      >
        <Grid item xs>
          <ListItemButton
            disableRipple
            sx={{ ...sx, pl: 4 * props.level + 1 }}
            onClick={() => {
              if (props.onCheckFunction) {
                props.onCheckFunction(
                  props.id,
                  props.checkedFunction
                    ? !props.checkedFunction(props.id)
                    : false
                );
              } else if (isExpandable()) setExpand(!expand);
            }}
          >
            <Grid
              container
              sx={{ minHeight: '252px' }}
              direction="column"
              justifyContent="center"
              alignItems="strech"
            >
              <Grid item xs={2}></Grid>
              <Grid container item xs>
                <CenterItem>
                  <ServiceTile id={props.service_tile ?? 0} />
                </CenterItem>
              </Grid>

              <Grid item xs={1}>
                <CenterItem>
                  <ListItemText color="#ffffff">
                    <h3>{props.children}</h3>
                  </ListItemText>
                </CenterItem>
              </Grid>

              {props.checkedFunction && props.onCheckFunction ? (
                <Grid container item xs>
                  <CenterItem>
                    <Checkbox
                      sx={{
                        color: props.checkedFunction(props.id)
                          ? '#7295C2 !important'
                          : '#C0C0C0 !important'
                      }}
                      checked={props.checkedFunction(props.id)}
                      onChange={(event) => {
                        if (props.onCheckFunction)
                          props.onCheckFunction(props.id, event.target.checked);
                      }}
                    />
                  </CenterItem>
                </Grid>
              ) : (
                <Grid item xs={1} sx={{ marginBottom: '20px' }}>
                  <CenterItem>
                    {props.status &&
                    (props.level === 0 ||
                      (props.status !== 1 && isExpandable() && !expand) ||
                      (props.status !== 1 && props.isComponent)) ? (
                      iconFromComponentStatus(props.status, 'large')
                    ) : (
                      <></>
                    )}
                  </CenterItem>
                </Grid>
              )}
            </Grid>
          </ListItemButton>
        </Grid>
        {props.showSubgroups ? (
          <Grid item xs>
            <ListItemButton
              disableRipple
              sx={{ ...sx, pl: 4 * props.level + 1 }}
              onClick={() => {
                if (isExpandable()) setExpand(!expand);
              }}
            >
              <CenterItem>
                {isExpandable() ? (
                  <ServiceTileArrow>
                    <MDIcon size={1} path={mdiMenuDown} color="#ffffff" />
                  </ServiceTileArrow>
                ) : (
                  <></>
                )}
              </CenterItem>
            </ListItemButton>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    );
  // is NOT a top level goup
  else
    listItemButton = (
      <ListItemButton
        disableRipple
        sx={{ ...sx, pl: 4 * props.level + 1 }}
        onClick={() => {
          if (isExpandable()) setExpand(!expand);
        }}
      >
        {isExpandable() ? (
          <ListItemIcon>
            {expand ? (
              <MDIcon size={1} path={mdiMenuDown} color="#ffffff" />
            ) : (
              <MDIcon size={1} path={mdiMenuRightOutline} color="#ffffff" />
            )}
          </ListItemIcon>
        ) : (
          <ListItemIcon></ListItemIcon>
        )}

        <ListItemText color="#ffffff">{props.children}</ListItemText>

        {props.status &&
        (props.level === 0 ||
          (props.status !== 1 && isExpandable() && !expand) ||
          (props.status !== 1 && props.isComponent)) ? (
          iconFromComponentStatus(props.status)
        ) : (
          <></>
        )}
      </ListItemButton>
    );

  return (
    <>
      {listItemButton}

      {isExpandable() ? (
        <Collapse in={expand}>
          {props.components?.length ? (
            <List
              key={'components_' + props.id}
              sx={{ paddingTop: 0, paddingBottom: 0 }}
            >
              {props.components
                ? props.components.map((c) => {
                    return (
                      <ListItem
                        id={props.id}
                        level={props.level + 1}
                        key={c.id}
                        status={c.status}
                        isComponent={true}
                        showSubgroups={props.showSubgroups}
                      >
                        {c.name}
                      </ListItem>
                    );
                  })
                : []}
            </List>
          ) : (
            <></>
          )}
          {props.subgroups?.length ? (
            <List
              key={'subgroups_' + props.id}
              sx={{ paddingTop: 0, paddingBottom: 0 }}
            >
              {renderComponents(props.subgroups, undefined, props.level + 1)}
            </List>
          ) : (
            <></>
          )}
        </Collapse>
      ) : (
        <></>
      )}
    </>
  );
}

function isScheduledMaintenanceActive(scheduledMaintenance: IIncident) {
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const tomorrow = Date.now() + oneDay;
  const yesterday = Date.now() - oneDay;
  const scheduledFrom = new Date(
    scheduledMaintenance.scheduled_maintenance?.scheduledFrom ?? tomorrow
  );
  const scheduledTo = new Date(
    scheduledMaintenance.scheduled_maintenance?.scheduledTo ?? yesterday
  );
  const isActive = scheduledFrom < now && now < scheduledTo;
  return isActive;
}

function incidentStatus(incident: IIncident) {
  let status: number = -1;

  if (incident.incident_updates?.length === 0) {
    return incident.status;
  }

  let latest: Date = new Date(incident.incident_updates[0].created_at);
  status = incident.incident_updates[0].status;

  for (const incident_update of incident.incident_updates) {
    let created_at = new Date(incident_update.created_at);
    if (created_at > latest) {
      latest = created_at;
      status = incident_update.status;
    }
  }
  return status;
}

function setGroupAndComponentIds(
  ids: Set<string>,
  scheduledMaintenance: IIncident
) {
  for (const component of scheduledMaintenance.components) {
    if (!ids.has(component.id)) {
      ids.add(component.id);
    }
  }

  for (const group of scheduledMaintenance.groups) {
    if (!ids.has(group.id)) {
      ids.add(group.id);
    }
  }
}

function isScheduledMaintenanceActiveAndUnfixed(
  group: IComponentGroup,
  scheduledMaintenances?: Array<IIncident>
) {
  if (!scheduledMaintenances) {
    return false;
  }

  const ids: Set<string> = new Set();

  for (const scheduledMaintenance of scheduledMaintenances) {
    if (
      scheduledMaintenance.scheduled_maintenance?.manuallyCreated &&
      isScheduledMaintenanceActive(scheduledMaintenance) &&
      incidentStatus(scheduledMaintenance) !== 4
    ) {
      setGroupAndComponentIds(ids, scheduledMaintenance);
    }
  }

  if (ids.has(group.id)) {
    return true;
  }

  if (group.components) {
    for (const component of group.components) {
      if (ids.has(component.id)) {
        return true;
      }
    }
  }

  return false;
}

function getGroupStatus(
  group: IComponentGroup,
  scheduledMaintenances?: Array<IIncident>
): number {
  if (
    scheduledMaintenances &&
    isScheduledMaintenanceActiveAndUnfixed(group, scheduledMaintenances)
  ) {
    return 2;
  }

  const statuses = [] as Array<number>;
  if (group.components) {
    for (const component of group.components) {
      statuses.push(component.status);
    }
  }
  if (group.subgroups) {
    for (const subgroup of group.subgroups) {
      statuses.push(getGroupStatus(subgroup, scheduledMaintenances));
    }
  }

  return Math.max(...statuses);
}

export function renderComponents(
  components: Array<IComponentGroup>,
  scheduledMaintenances?: Array<IIncident>,
  level: number = 0,
  showSubgroups: boolean = true,
  onCheckFunction?: any,
  checkedFunction?: any
): any {
  if (components.length === 0) return [];

  const lis = [];
  for (const group of components) {
    const li = (
      <ListItem
        id={group.id}
        level={level}
        service_tile={group.service_tile}
        subgroups={group.subgroups}
        components={group.components}
        status={getGroupStatus(group, scheduledMaintenances)}
        key={group.id}
        onCheckFunction={onCheckFunction}
        checkedFunction={checkedFunction}
        showSubgroups={showSubgroups}
      >
        {group.name}
      </ListItem>
    );

    if (level === 0) {
      lis.push(
        <Grid item xs={6} xl={3} key={group.id}>
          <List>{li}</List>
        </Grid>
      );
    } else {
      lis.push(li);
    }
  }

  return lis;
}

const ComponentView: React.FC<IComponentView> = ({
  components,
  scheduledMaintenances
}: IComponentView) => {
  return (
    <div>
      <SectionHeader title="Services" />
      {components && components.length ? (
        <Grid container spacing={2}>
          {renderComponents(components, scheduledMaintenances)}
        </Grid>
      ) : (
        []
      )}
    </div>
  );
};

export default ComponentView;
