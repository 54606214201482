import { useContext, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Tooltip } from '@mui/material';
import styled from 'styled-components';
import AdminSectionHeader from '../components/Admin/AdminSectionHeader';
import EditModal from '../components/Admin/EditModal';
import GroupForm from '../components/Admin/Forms/GroupForm';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import { IComponentGroup } from '../interfaces';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackbarContext from '../contexts/SnackbarContext';

const AdminGroupPage = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<any>(null);
  const [componentGroups, setComponentGroups] = useState<any>();
  const { handleResponse } = useContext(SnackbarContext);

  const fetchComponentGroups = async () => {
    try {
      const response = await fetch(
        '/api/v1/components/groups?hierarchy=false&getInvisible=true'
      );
      setComponentGroups((await response.json()).data);
    } catch (error) {
      console.error(error);
    }
  };

  function discourageFromDeleting(group: IComponentGroup): boolean {
    if (group.service_tile) return true;
    return false;
  }

  useEffect(() => {
    fetchComponentGroups();
  }, []);

  const handleEditComponentGroup = (cg: any) => {
    setFormContent(
      <GroupForm
        setModalOpen={setModalOpen}
        componentGroup={cg}
        fetchGroups={fetchComponentGroups}
        allComponentGroups={componentGroups}
      />
    );
    setModalOpen(true);
  };

  const handleDeleteComponentGroupCallback = () => {
    setModalOpen(false);
    fetchComponentGroups();
  };

  const handleDeleteComponentGroup = async (group_id: string) => {
    try {
      const response = await fetch('/api/v1/components/groups/' + group_id, {
        method: 'DELETE',
        headers: AuthenticationService.instance.headers
      });

      handleResponse(
        response,
        'group',
        'delete',
        handleDeleteComponentGroupCallback
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddComponentGroup = () => {
    setFormContent(
      <GroupForm
        setModalOpen={setModalOpen}
        allComponentGroups={componentGroups}
        fetchGroups={fetchComponentGroups}
      />
    );
    setModalOpen(true);
  };

  return (
    <div>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={formContent}
      />
      <AdminSectionHeader
        title="Component groups"
        buttonText="Add new component group"
        handleClick={() => handleAddComponentGroup()}
      ></AdminSectionHeader>
      <div>
        {componentGroups &&
          componentGroups.map((g: any) => {
            return (
              <div key={g.id} style={{ display: 'flex', width: '100%' }}>
                <CardStyled boxshadow="true">
                  <CardContentStyled padding={'1em'}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="h5" component="div">
                        {g.name}
                        {g.visible ? (
                          <></>
                        ) : (
                          <VisibilityOffIcon
                            sx={{
                              marginLeft: '10px',
                              color: '#ff9900'
                            }}
                          ></VisibilityOffIcon>
                        )}
                      </Typography>

                      <div>
                        <Button
                          variant="outlined"
                          style={{ marginRight: '10px' }}
                          onClick={() => handleEditComponentGroup(g)}
                        >
                          Edit
                        </Button>
                        {discourageFromDeleting(g) ? (
                          <Tooltip
                            title="The group has a service tile assigned to it. It is therefore discouraged from deletion."
                            placement="left-start"
                          >
                            <span>
                              <Button
                                variant="contained"
                                disabled
                                onClick={() => handleDeleteComponentGroup(g.id)}
                              >
                                Delete
                              </Button>
                            </span>
                          </Tooltip>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => handleDeleteComponentGroup(g.id)}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                    <div>
                      {g.supergroup ? (
                        <Typography variant="body1">
                          Grouped under:{' '}
                          {
                            componentGroups.filter(
                              (el: any) => el.id === g.supergroup.id
                            )[0]?.name
                          }
                        </Typography>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </CardContentStyled>
                </CardStyled>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AdminGroupPage;

const CardStyled = styled(Card)<any>`
  box-shadow: ${({ boxshadow }) =>
    boxshadow
      ? '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important'
      : 'none !important'};
  border-radius: 0 !important;
  margin-bottom: 20px;
  width: 100%;
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #414141;
  ${({ padding }) => ('padding: ' + padding ? padding : '0px !important')};
  color: #ffffff;
`;
