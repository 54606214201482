import styled from 'styled-components';
import { AppBar, Grid, Tooltip, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { mdiHelpCircleOutline, mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';

const CirleIconLink = styled.a`
  font-size: 0px;
  padding: 0px 10px;
`;

const AnchorNoUnderline = styled.a`
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

const DateTimeDisplay = styled.p`
  font-size: 18px;
  margin: 0;
  text-align: right;
`;

function getTimeZone() {
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? '+' : '-';
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2, '0');
  const offsetMinutes = Math.floor(offset % 60)
    .toString()
    .padStart(2, '0');

  return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
}

const timezone = getTimeZone();

const getCurrentFormattedTime = () => {
  return moment().format('D.M.YYYY\n HH:mm:ss');
};

interface IStatuspageAppBar {
  onAdminPage: boolean;
}

function StatuspageAppBar({ onAdminPage }: IStatuspageAppBar) {
  const [dateTime, setDateTime] = useState(getCurrentFormattedTime());

  useEffect(() => {
    const secTimer = setInterval(() => {
      setDateTime(getCurrentFormattedTime());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  return (
    <AppBar position="static" color="secondary">
      <Grid
        container
        alignItems="center"
        sx={{ height: '48px', backgroundColor: '#333333' }}
      >
        <Grid item container xs={'auto'} sx={{ marginLeft: '16px' }}>
          <a href="/">
            <img
              height={'32px'}
              width={'32px'}
              src={
                process.env.PUBLIC_URL +
                '/assets/icon/logo/dark/ifm-standard-logo.svg'
              }
              alt="IFM Logo"
            />
          </a>
        </Grid>
        <Grid item container xs>
          <AnchorNoUnderline href="/">
            <Typography
              variant="h6"
              sx={{
                marginLeft: '10px',
                fontSize: '18px',
                textDecoration: 'none'
              }}
            >
              ifm cloud status
            </Typography>
          </AnchorNoUnderline>
        </Grid>
        <Grid
          container
          item
          xs={2}
          spacing={1}
          alignItems="center"
          justifyItems="right"
        >
          <Grid item xs></Grid>
          {onAdminPage ? (
            <Grid item xs={'auto'}>
              <Tooltip title={`Documentation`} placement="bottom">
                <CirleIconLink href="https://ifm-world.atlassian.net/wiki/spaces/SFCS">
                  <Icon path={mdiHelpCircleOutline} size={1}></Icon>
                </CirleIconLink>
              </Tooltip>
            </Grid>
          ) : (
            []
          )}

          <Grid item xs={'auto'}>
            <Tooltip
              title={`The time zone is automatically retrieved from your browser (UTC${timezone}).`}
              placement="bottom"
            >
              <Icon path={mdiInformationOutline} size={1}></Icon>
            </Tooltip>
          </Grid>
          <Grid item xs={4} sx={{ marginRight: '32px', textAlign: 'right' }}>
            <DateTimeDisplay>{dateTime}</DateTimeDisplay>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default StatuspageAppBar;
