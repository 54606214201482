import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Chip
} from '@mui/material';
import AdminSectionHeader from '../components/Admin/AdminSectionHeader';
import styled from 'styled-components';
import EditModal from '../components/Admin/EditModal';
import { useContext, useState } from 'react';
import ScheduledMaintenanceForm from '../components/Admin/Forms/ScheduledMaintenanceForm';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import ReactMarkdown from 'react-markdown';
import { IComponent, IComponentGroup } from '../interfaces';
import { collapseLinebreaks } from '../utils';
import SnackbarContext from '../contexts/SnackbarContext';

interface IAdminScheduledPage {
  scheduled: Array<any>;
  fetchScheduledMaintenances: any;
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
}

const AdminScheduledPage = ({
  scheduled,
  fetchScheduledMaintenances,
  components,
  componentGroups
}: IAdminScheduledPage) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<any>(null);
  const { handleResponse } = useContext(SnackbarContext);

  const handleDeleteScheduledMaintenanceCallback = () => {
    setModalOpen(false);
    fetchScheduledMaintenances();
  };

  const handleDeleteScheduledMaintenance = async (scheduled_id: string) => {
    try {
      const response = await fetch(
        '/api/v1/scheduled_maintenances/' + scheduled_id,
        {
          method: 'DELETE',
          headers: AuthenticationService.instance.headers
        }
      );

      handleResponse(
        response,
        'scheduled maintenance',
        'delete',
        handleDeleteScheduledMaintenanceCallback
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddScheduledMaintenance = () => {
    setFormContent(
      <ScheduledMaintenanceForm
        setModalOpen={setModalOpen}
        fetchScheduledMaintenances={fetchScheduledMaintenances}
        components={components}
        componentGroups={componentGroups}
      />
    );
    setModalOpen(true);
  };

  const handleEditScheduledMaintenance = (scheduledMaintenance: any) => {
    setFormContent(
      <ScheduledMaintenanceForm
        setModalOpen={setModalOpen}
        fetchScheduledMaintenances={fetchScheduledMaintenances}
        components={components}
        componentGroups={componentGroups}
        scheduledMaintenance={scheduledMaintenance}
      />
    );
    setModalOpen(true);
  };

  return (
    <div>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={formContent}
      />
      <AdminSectionHeader
        title="Scheduled Maintenances"
        handleClick={() => handleAddScheduledMaintenance()}
        buttonText="Add new scheduled maintenance"
      ></AdminSectionHeader>
      <div>
        {scheduled.map((i) => {
          return (
            <div key={i.id}>
              <CardStyled boxshadow="true">
                <CardContentStyled padding={'1em'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ marginBottom: '5px' }}
                    >
                      {i.name}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ marginRight: '10px' }}
                    >
                      {new Date(i.created_at).toLocaleString()}
                    </Typography>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <Grid container>
                        {(i.groups ?? []).concat(i.components).map((c: any) => {
                          return (
                            <Chip
                              label={c.name}
                              sx={{
                                border: '#7295C2',
                                background: '#7295C280',
                                color: '#ffffff',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                marginRight: '20px',
                                marginBottom: '5px'
                              }}
                              key={c.id}
                            />
                          );
                        })}
                      </Grid>

                      <div style={{ marginTop: '5px' }}>
                        <ReactMarkdown>
                          {collapseLinebreaks(i.message)}
                        </ReactMarkdown>
                      </div>
                      <div style={{ marginTop: '5px' }}>
                        <b>Scheduled from:</b>{' '}
                        {i.scheduledFrom
                          ? new Date(i.scheduledFrom).toLocaleString()
                          : ''}
                      </div>
                      <div style={{ marginTop: '5px' }}>
                        <b>Scheduled until:</b>{' '}
                        {i.scheduledTo
                          ? new Date(i.scheduledTo).toLocaleString()
                          : ''}
                      </div>
                    </div>
                    <div style={{ marginTop: 'auto', minWidth: '180px' }}>
                      <Button
                        variant="outlined"
                        style={{ marginRight: '10px' }}
                        onClick={() => handleEditScheduledMaintenance(i)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleDeleteScheduledMaintenance(i.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </CardContentStyled>
              </CardStyled>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminScheduledPage;

const CardStyled = styled(Card)<any>`
  box-shadow: ${({ boxshadow }) =>
    boxshadow
      ? '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important'
      : 'none !important'};
  border-radius: 0 !important;
  margin-bottom: 20px;
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #414141;
  ${({ padding }) => ('padding: ' + padding ? padding : '0px !important')};
  color: #ffffff;
`;
