import jwt_decode, { JwtPayload } from 'jwt-decode';
import store from 'store';

export interface IUser {
  email: string;
  id: string;
}

type LoginReponse = {
  accessToken: string;
  user: {
    email: string;
    userId: string;
  };
};

export class AuthenticationService {
  private static _instance: AuthenticationService;
  private _hasAccess = false;
  private _decodedJwt: any = null;

  constructor() {
    if (this.accessToken && !this.isExpired(this.accessToken)) {
      this._hasAccess = true;
    }
  }

  public static get instance() {
    if (!this._instance) {
      this._instance = new AuthenticationService();
    }

    return this._instance;
  }

  public get userId(): string | null {
    return this._decodedJwt?.userId || null;
  }

  private isExpired(token: any): boolean {
    try {
      const decoded = jwt_decode<JwtPayload>(token);
      if (decoded.exp) {
        return Date.now() >= (decoded.exp - 60) * 1000;
      }
      return true;
    } catch {
      return true;
    }
  }

  private get accessToken() {
    return store.get('accessToken');
  }

  private set accessToken(token: string | null) {
    if (token) {
      this._decodedJwt = jwt_decode(token);
    }

    store.set('accessToken', token);
  }

  public get hasAccess() {
    return this._hasAccess;
  }

  public get headers() {
    return {
      Authorization: 'Bearer ' + this.accessToken,
      'Content-Type': 'application/json'
    };
  }

  /**
   * Check if user has access to adminpage
   * @returns user credentials vaild
   */
  async login(email: string, password: string): Promise<boolean> {
    const response = await fetch('/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    const json = (await response.json()) as LoginReponse;

    if (response.status === 200) {
      this.accessToken = json.accessToken;
      this._hasAccess = true;
      return true;
    }

    return false;
  }

  public testToken(): boolean {
    if (this.accessToken) {
      return !this.isExpired(this.accessToken);
    }
    return false;
  }
}
