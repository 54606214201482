import { Button } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import styled from 'styled-components';

const SubscribeButtonStyled = styled(Button)`
  color: #ffffff !important;
  background-color: #555555 !important;
  text-transform: none !important;
  border: 1px solid !important;
  border-color: #333333aa !important;
`;

interface ISubscribeButton {
  handleClick: any;
  buttonText: string;
}

const SubscribeButton = ({ handleClick, buttonText }: ISubscribeButton) => (
  <SubscribeButtonStyled
    disableRipple
    variant="contained"
    onClick={handleClick}
    startIcon={<TelegramIcon />}
  >
    {buttonText}
  </SubscribeButtonStyled>
);

export default SubscribeButton;
