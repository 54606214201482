import { ListItemButton } from '@mui/material';
import { ServiceTileArrow } from '../../ComponentView';
import MDIcon from '@mdi/react';
import { mdiMenuDown } from '@mdi/js';
import CheckBoxList from './CheckBoxList';
import { useState } from 'react';
import { IComponentGroup } from '../../../interfaces';

interface ICustomListItemButton {
  groups: Array<IComponentGroup>;
  filterBy: Array<string>;
  selectFilterBy: Function;
}

const CustomListItemButton = ({
  groups,
  filterBy,
  selectFilterBy
}: ICustomListItemButton) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [isExtended, setIsExtended] = useState(false);

  const handlePopperToggle = (event: any) => {
    event.stopPropagation();
    setIsExtended(!isExtended);
    setAnchorEl(event.currentTarget);
  };

  function handleClickAway() {
    setIsExtended(false);
  }

  return (
    <>
      <ListItemButton
        sx={{ width: 5, height: 30, pl: 1 }}
        disableRipple
        onClick={(e) => handlePopperToggle(e)}
      >
        <ServiceTileArrow style={{ backgroundColor: 'transparent' }}>
          <MDIcon size={1} path={mdiMenuDown} color="#ffffff" />
        </ServiceTileArrow>
      </ListItemButton>
      <CheckBoxList
        handleClickAway={handleClickAway}
        isExtended={isExtended}
        anchorEl={anchorEl}
        groups={groups}
        filterBy={filterBy}
        handleCheckBoxClick={selectFilterBy}
      />
    </>
  );
};

export default CustomListItemButton;
