import '../App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import AdminTabs from '../components/Admin/AdminTabs';
import { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import AdminIncidentPage from './AdminIncidentPage';
import AdminComponentPage from './AdminComponentsPage';
import AdminScheduledPage from './AdminScheduledPage';
import AdminReleaseNotePage from './AdminReleaseNotePage';
import WithAuthentication from '../components/WithAuthentication';
import AdminGroupPage from './AdminGroupPage';
import { IComponentGroup } from '../interfaces';
import { PAGE_SIZE_ADMIN } from '../constants';
import StatuspageAppBar from '../components/StatuspageAppBar';
import AdminUsersPage from './AdminUsersPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9900'
    },
    secondary: {
      main: '#333333'
    },
    background: {
      default: '#222222'
    }
  }
});

const MainContent = styled.div`
  color: #ffffff;
  padding: 0px 30px;
  overflow: auto;
`;

// TODO Divider does not render!

function AdminPage() {
  const [tabId, setTabId] = useState(0);
  const [components, setComponents] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [scheduledMaintenances, setScheduledMaintenances] = useState([]);
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [markdownTemplates, setMarkdownTemplates] = useState([]);
  const [componentGroups, setComponentGroups] = useState<IComponentGroup[]>([]);
  const [pageIncidents, setPageIncidents] = useState<number>(0);
  const [allIncidentsLoaded, setAllIncidentsLoaded] = useState<boolean>(false);

  /* FETCH DATA */

  async function fetchComponents() {
    try {
      const response = await fetch('/api/v1/components/');
      setComponents((await response.json()).data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchComponentGroups() {
    try {
      const response = await fetch(
        '/api/v1/components/groups?hierarchy=false&getInvisible=true'
      );

      setComponentGroups((await response.json()).data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchScheduledMaintenances() {
    try {
      const response = await fetch('/api/v1/scheduled_maintenances');
      setScheduledMaintenances((await response.json()).data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchIncidents(page = 0) {
    const offset = page === 0 ? 0 : incidents.length;
    try {
      const response = await fetch(
        `/api/v1/incidents?limit=${PAGE_SIZE_ADMIN}&offset=${offset}`
      );
      const newIncidents = (await response.json()).data;
      if (newIncidents.length && newIncidents.length < PAGE_SIZE_ADMIN) {
        setAllIncidentsLoaded(true);
      }
      setIncidents((current) =>
        page === 0 ? newIncidents : current.concat(newIncidents)
      );
    } catch (error) {
      console.error(error);
    }
  }

  const onScroll = useCallback(() => {
    if (tabId !== 0) return;
    if (
      window.innerHeight + window.scrollY + 0.5 >=
      document.body.scrollHeight
    ) {
      console.log(
        'incidents.length',
        incidents.length,
        Math.floor(incidents.length / PAGE_SIZE_ADMIN)
      );
      setPageIncidents(Math.floor(incidents.length / PAGE_SIZE_ADMIN));
    }
  }, [tabId, incidents]);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  async function fetchReleaseNotes() {
    try {
      const response = await fetch('/api/v1/release_notes');
      setReleaseNotes((await response.json()).data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchMarkdownTemplates() {
    try {
      const response = await fetch('/api/v1/markdown_templates');
      setMarkdownTemplates((await response.json()).data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchComponents();
  }, []);

  /* eslint-disable */
  useEffect(() => {
    fetchIncidents(pageIncidents);
  }, [pageIncidents]);
  /* eslint-disable */

  useEffect(() => {
    fetchScheduledMaintenances();
  }, []);

  useEffect(() => {
    fetchReleaseNotes();
  }, []);

  useEffect(() => {
    fetchMarkdownTemplates();
  }, []);

  useEffect(() => {
    fetchComponentGroups();
  }, []);

  return (
    <WithAuthentication>
      <ThemeProvider theme={theme}>
        <StatuspageAppBar onAdminPage={true} />
        <Grid container>
          <Grid item xs={0} lg={1}></Grid>
          <Grid item xs>
            <div style={{ marginTop: '20px' }}>
              <MainContent>
                <AdminTabs
                  tabId={tabId}
                  handleChange={(event: any, newValue: number) =>
                    setTabId(newValue)
                  }
                ></AdminTabs>
                {tabId === 0 ? (
                  <AdminIncidentPage
                    incidents={incidents}
                    fetchIncidents={fetchIncidents}
                    allIncidentsLoaded={allIncidentsLoaded}
                    components={components}
                    componentGroups={componentGroups}
                  />
                ) : tabId === 1 ? (
                  <>
                    <AdminComponentPage
                      components={components}
                      componentGroups={componentGroups}
                      fetchComponents={fetchComponents}
                    />
                  </>
                ) : tabId === 2 ? (
                  <>
                    <AdminGroupPage />
                  </>
                ) : tabId === 3 ? (
                  <>
                    <AdminScheduledPage
                      scheduled={scheduledMaintenances}
                      fetchScheduledMaintenances={fetchScheduledMaintenances}
                      components={components}
                      componentGroups={componentGroups}
                    />
                  </>
                ) : tabId === 4 ? (
                  <>
                    <AdminReleaseNotePage
                      releaseNotes={releaseNotes}
                      markdownTemplates={markdownTemplates}
                      fetchMarkdownTemplates={fetchMarkdownTemplates}
                      fetchReleaseNotes={fetchReleaseNotes}
                      components={components}
                      componentGroups={componentGroups}
                    ></AdminReleaseNotePage>
                  </>
                ) : (
                  <>
                    <AdminUsersPage />
                  </>
                )}
              </MainContent>
            </div>
          </Grid>
          <Grid item xs={0} lg={1}></Grid>
        </Grid>
      </ThemeProvider>
    </WithAuthentication>
  );
}

export default AdminPage;
