import {
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  Grid,
  Tooltip
} from '@mui/material';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { IComponentGroup } from '../../../interfaces';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import { v4 as uuidv4 } from 'uuid';
import useValidation from '../../../hooks/useValidation';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IEditGroupForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allComponentGroups: Array<IComponentGroup>;
  componentGroup?: IComponentGroup;
  fetchGroups: any;
}

const GroupForm = ({
  setModalOpen,
  allComponentGroups,
  componentGroup, //if exists: group is updated
  fetchGroups
}: IEditGroupForm) => {
  const [supergroupId, setSupergroupId] = useState<string | null>(
    componentGroup?.supergroup?.id ?? '0'
  );
  const [name, setName] = useState<string | null>(componentGroup?.name || '');
  const [visible, setVisible] = useState<boolean>(
    componentGroup?.visible ?? true
  );
  const [filterableForReleaseNotes, setFilterableForReleaseNotes] =
    useState<boolean>(componentGroup?.filterableForReleaseNotes ?? true);
  const [release_notes_only, setReleaseNotesOnly] = useState<boolean>(
    componentGroup?.release_notes_only ?? false
  );

  const validateName = (name: string | null) => {
    return !!name;
  };
  const [nameValid, showNameValid, updateNameDisplay] = useValidation(
    validateName,
    name
  );

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchGroups();
  };

  const handleAddGroup = async () => {
    if (nameValid) {
      try {
        const response = await fetch('/api/v1/components/groups', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            id: uuidv4(),
            name,
            supergroup_id: supergroupId === '0' ? null : supergroupId,
            visible,
            filterableForReleaseNotes,
            release_notes_only
          })
        });

        handleResponse(response, 'group', 'add', responseCallback);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleEditGroup = async () => {
    if (!componentGroup?.id) {
      setSnackMessage('Error: group id not given');
      setSnackSeverity('error');
      setSnackOpen(true);
      return;
    }

    if (nameValid) {
      try {
        const response = await fetch(
          '/api/v1/components/groups/' + componentGroup.id,
          {
            method: 'PUT',
            headers: AuthenticationService.instance.headers,
            body: JSON.stringify({
              name,
              supergroup_id: supergroupId === '0' ? null : supergroupId,
              visible,
              filterableForReleaseNotes,
              release_notes_only
            })
          }
        );

        handleResponse(response, 'group', 'edit', responseCallback);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Edit component group
        </Typography>
      </div>

      <div>
        <TextFieldStyled
          {...(showNameValid
            ? { error: false }
            : { error: true, helperText: 'This field is required' })}
          label="Name*"
          id="name"
          size="small"
          value={name}
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          onChange={(e) => setName(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
          onBlur={() => updateNameDisplay()}
        />

        <Grid container alignItems="center" sx={{ margin: '10px' }}>
          <h3>Visible</h3>
          <Checkbox
            checked={visible}
            onChange={(event) => setVisible(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>

        <Tooltip
          title="If checked, the user can only filter for this group on the release note tab."
          placement="left-start"
        >
          <Grid container alignItems="center" sx={{ margin: '10px' }}>
            <h3>Release notes only</h3>
            <Checkbox
              checked={release_notes_only}
              onChange={(event) => setReleaseNotesOnly(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
        </Tooltip>

        <Grid container alignItems="center" sx={{ margin: '10px' }}>
          <h3>Filterable</h3>
          <Checkbox
            checked={filterableForReleaseNotes}
            onChange={(event) =>
              setFilterableForReleaseNotes(event.target.checked)
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>

        <FormControl
          sx={{
            marginTop: '20px',
            color: '#b7b7b7',
            svg: { color: '#b7b7b7' }
          }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="groupLabel"
            sx={{
              color: '#b7b7b7'
            }}
          >
            Select component groups
          </InputLabel>
          <Select
            labelId="groupLabel"
            id="group"
            value={supergroupId}
            onChange={(event) => setSupergroupId(event.target.value)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
          >
            <MenuItem key={0} value={'0'}>
              None
            </MenuItem>
            {allComponentGroups &&
              allComponentGroups.map((g: any) => (
                <MenuItem key={g.id} value={g.id}>
                  {g.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Typography variant="body2" color="#b7b7b7" sx={{ marginTop: '8px' }}>
          *required fields
        </Typography>
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              componentGroup?.id ? handleEditGroup() : handleAddGroup()
            }
            disabled={!nameValid}
          >
            {componentGroup?.id ? 'Edit' : 'Add'} Group
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GroupForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
