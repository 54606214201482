import { Button } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  color: #ffffff;
  background-color: #555555;
  padding: 20px;
  margin: 24px 0px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ISectionHeader {
  title: string;
  handleClick: () => void;
  handleClickButtonLeft?: () => void;
  buttonText: string;
  buttonLeftText?: string;
}

const AdminSectionHeader: React.FC<ISectionHeader> = ({
  title,
  handleClick,
  handleClickButtonLeft,
  buttonText,
  buttonLeftText
}: ISectionHeader) => {
  return (
    <Container>
      <h1>{title}</h1>
      <div>
        {buttonLeftText ? (
          <Button
            variant="outlined"
            onClick={handleClickButtonLeft}
            sx={{ mr: 1 }}
          >
            {buttonLeftText}
          </Button>
        ) : (
          []
        )}
        <Button variant="outlined" onClick={handleClick}>
          {buttonText}
        </Button>
      </div>
    </Container>
  );
};

export default AdminSectionHeader;
