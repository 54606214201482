import PrivacyNoticeHeader from './PrivacyNoticeHeader';
import PrivacyNoticeText from './PrivacyNoticeText';

const PrivacyNoticeContent: React.FC<{}> = () => {
  return (
    <>
      <PrivacyNoticeHeader
        text={
          '1. Processing of personal data related to your use of our websites, applications and online services'
        }
      >
        <PrivacyNoticeText>
          <h2>a) Categories of personal data processed</h2>
          When visiting our websites or using our applications or online
          services (all: online services), we process information that you
          actively and voluntarily provided about yourself or which has been
          generated by us in connection with your use of our online services and
          includes the following categories of personal data:
          <ul>
            <li>
              your contact information, including name, email address, phone
              number
            </li>
            <li>
              further personal data that you provide by filling in forms in our
              online services
            </li>
            <li>
              information submitted as part of a support request, survey or
              comment or forum post
            </li>
            <li>
              information on your interaction in our online services including
              your device and user identifier, information on your operating
              system, sites and services accessed during your visit, the date
              and time of each visitor request.
            </li>
          </ul>
          <h2>b) Purpose of the processing</h2>
          We process your personal data for the following purposes:
          <ul>
            <li>
              to provide our online services and functions including creating
              and administering your online account, updating, securing and
              troubleshooting, providing support, as well as improving and
              developing our online services
            </li>
            <li>to bill your use of our online services</li>
            <li>for your identification</li>
            <li>to answer and fulfill your requests or instructions</li>
            <li>
              to process your order or to provide you with access to specific
              information or offers
            </li>
            <li>
              to contact you with information and offers concerning our products
              and services
            </li>
            <li>
              to send you further marketing information or to contact you in the
              context of customer satisfaction surveys
            </li>
            <li>
              to enforce the terms of our online services, to establish or
              preserve a legal claim or defense, to prevent fraud or other
              illegal activities including attacks on our information technology
              systems.
            </li>
          </ul>
          <h2>c) Cookies</h2>
          Our online services may use cookies, small files containing certain
          information, that are stored on your device. If used by us without
          your consent, these cookies are strictly necessary to provide certain
          functionalities of our online services to you or to provide you with a
          service that you requested in our online services. Other cookies will
          only be used if you have given your consent.
          <h2>d) Online services provided by you</h2>
          Our online services may be provided for your use or for the
          organization you belong to like our enterprise customers. If your
          organization provides you with access to our online services, our
          processing of personal data provided by or collected from you or your
          organization in connection with the content of the online services is
          performed under the direction of your organization and is subject to a
          data processing agreement between your organization and us. In such
          instance, your organization is responsible for any personal data
          contained in such content and you should direct any questions about
          how personal data contained in such content is used to your
          organization
          <h2>e) Links to other websites</h2>
          This privacy notice applies only to online services that link to this
          privacy notice and not to other websites or applications. This privacy
          notice does not apply in situations where we process information on
          behalf of our business partners (when we act as a web hosting or cloud
          provider). We may provide links to other websites and applications
          which we believe could be of interest to you. We are not responsible
          for the privacy practices of these websites or applications.
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader
        text={
          '2. Processing of personal data related to your business relationship with us'
        }
      >
        <PrivacyNoticeText>
          <h2>a) Categories of personal data processed</h2>
          In the context of your business relationship with us, we may process
          the following categories of personal data of consumers and contact
          persons at (prospective) customers, suppliers, vendors and partners
          (all: business partners):
          <ul>
            <li>
              contact information, such as full name, job position, work
              address, work telephone number, work mobile phone number, work fax
              number and work email address
            </li>
            <li>
              payment data necessary for processing payments and fraud
              prevention, including credit/debit card numbers, security code
              numbers and other related billing information
            </li>
            <li>
              further information necessarily processed in a project or
              contractual relationship with us or voluntarily provided by our
              business partner like personal data relating to orders placed,
              payments made, requests, and project milestones
            </li>
            <li>
              personal data collected from publicly available resources
              (including business and employment oriented social networks and
              websites), integrity data bases and credit agencies
            </li>
            <li>
              if legally required for our business partners compliance
              screenings: date of birth, ID numbers, identity cards and
              information about relevant and significant litigation or other
              legal proceedings against our business partners.
            </li>
          </ul>
          <h2>b) Purpose of the processing</h2>
          We may process the personal data for the following purposes:
          <li>
            communicating with our business partners about products, services
            and projects by responding to inquiries or requests or providing you
            with information about purchased products
          </li>
          <li>
            planning, performing and managing the (contractual) relationship
            with our business partners by performing transactions and orders of
            products or services, processing payments, performing accounting,
            auditing, billing and collection activities, arranging shipments and
            deliveries, facilitating repairs and providing support services
          </li>
          <li>
            administrating and performing market analysis, sweepstakes, contests
            or other customer activities or events
          </li>
          <li>
            contacting you with information and offers concerning our products
            and services, sending you further marketing messages and conducting
            customer satisfaction surveys
          </li>
          <li>
            maintaining and protecting the security of our products, services
            and websites, preventing and detecting security threats, fraud or
            other criminal or malicious activities
          </li>
          <li>
            ensuring compliance with legal obligations (like record keeping
            obligations), export control and customs, our business partners
            compliance screening obligations (to prevent white-collar or money
            laundering crimes) and our policies or industrial standards
          </li>
          <li>
            solving disputes, enforce our contractual agreements and to
            establish, exercise or defend legal claims.
          </li>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="3. Processing of personal data for customer satisfaction surveys and for direct marketing">
        <PrivacyNoticeText>
          As permitted under applicable law, we may process your contact
          information for direct marketing purposes (fair or trade show
          invitations, newsletters with further information and offers
          concerning our products and services) and to carry out customer
          satisfactions surveys, in all cases also by email. You may object to
          the processing of your contact data for these purposes any time by
          writing to gdpr [at] ifm.com or by using the opt-out mechanism
          provided in the certain communication you received.
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="4. Transfer and disclosure of personal data">
        <PrivacyNoticeText>
          We may transfer your personal data to:
          <ul>
            <li>
              within the ifm group of companies, other affiliated companies or
              third parties – sales partners or suppliers – in connection with
              your use of our online services or our business relationship with
              you
            </li>
            <li>
              third parties that provide IT services to us and that process data
              only for the purpose of these services (hosting or IT maintenance
              and support services)
            </li>
            <li>
              third parties in connection with complying with legal obligations
              or establishing, exercising or defending rights or claims (like
              for court and arbitration proceedings, to regulators, law
              enforcement and government authorities, to attorneys and
              consultants).
            </li>
          </ul>
          <p>
            The recipients of your personal data may be located outside the
            country you reside in. Personal data published by you on our online
            services (like chat rooms or forums) may be globally accessible to
            other registered user of the certain online service.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="5. Retention Periods">
        <PrivacyNoticeText>
          We erase your personal data if the retention of that personal data is
          no longer necessary for the purposes for which they were collected or
          otherwise processed unless we have to comply with legal obligations
          (like retention obligations due to tax law or commercial law) or
          unless indicated different at the time of the collection of your
          personal data (like by a form completed by you).
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="6. Your rights">
        <PrivacyNoticeText>
          <p>
            The data protection laws in the jurisdiction you reside in may
            entitle you to specific rights in relation to your personal data.
          </p>
          <p>
            In particular – subject to the legal requirements – you may be
            entitled to:
          </p>
          <ul>
            <li>
              get confirmation from us if or not personal data concerning you
              are being processed and – in case – access to the personal data
            </li>
            <li>
              obtain from us the correction of inaccurate personal data
              concerning you
            </li>
            <li>demand the erasure of your personal data</li>
            <li>
              obtain from us restriction of processing regarding your personal
              data
            </li>
            <li>
              transfer concerning personal data that you actively provided (data
              portability)
            </li>
            <li>
              object to further processing of personal data concerning you.
            </li>
          </ul>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="7. Security">
        <PrivacyNoticeText>
          To protect your personal data against accidental or unlawful
          destruction, loss, use or alteration and against unauthorized
          disclosure or access, we use adequate physical, technical and
          organizational security measures (TOM).
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="8. Data Privacy Contact">
        <PrivacyNoticeText>
          <p>
            Our data privacy organization provides support with any data privacy
            related questions, comments, concerns or complaints or in case you
            wish to exercise any of your data privacy related rights. The data
            privacy organization may be contacted at gdpr [at] ifm.com.
          </p>
          <p>
            The data privacy organization of ifm will be glad to help and is
            willing to make significant efforts to address and settle any
            requests or complaints you bring to its attention. Besides
            contacting the data privacy organization of ifm you have the right
            to approach the data protection authority with your request or
            complaint.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="9. Processing according to GDPR: controller, legal basis and international transfers">
        <PrivacyNoticeText>
          <p>
            This section applies and provides you with further information if
            your personal data is processed by one of our companies located in
            the European Economic Area.
          </p>
          <h2>a) Data Controller</h2>
          <p>
            The specific company identified on this page as being the operator
            of this website is the data controller in the meaning of the General
            Data Protection Regulation (GDPR) for the processing activities
            described in this privacy notice.
          </p>
          <p>
            In the course of our business relationship with you, we may share
            business partner contact information with affiliated ifm companies.
            We and these ifm companies are jointly responsible for the proper
            protection of your personal data (Art. 26 GDPR). To allow you to
            effectively exercise your data subject rights in the context of this
            joint controllership, we entered into an agreement with these ifm
            companies granting you the right to centrally exercise your data
            subject rights against ifm electronic gmbh, Germany.
          </p>
          <p>
            To exercise your rights please contact us via gdpr [at] ifm.com or
            under the above mentioned contact details.
          </p>
          <h2>b) Legal basis of the processing</h2>
          <p>
            The GDPR obliges us to share the legal basis of the processing
            personal data. The legal basis for our processing data about you is
            that such processing is necessary for the purposes of
          </p>
          <ul>
            <li>
              exercising our rights and performing our obligations under any
              contract we make with you (Art. 6 Abs. 1 lit. b GDPR – contract
              performance)
            </li>
            <li>
              compliance with our legal obligations (Art. 6 Abs. 1 lit. c GDPR –
              compliance with legal obligations)
            </li>
            <li>
              legitimate interests pursued by us (Art. 6 Abs. 1 lit. f GDPR –
              legitimate interest). Generally, the legitimate interest pursued
              by us in relation to our use of your personal data is the
              efficient performance or management of your use of our online
              services or our business relationship with you. Where the below
              table states that we rely on our legitimate interest for a given
              purpose, we are of the opinion that our legitimate interest is not
              overridden by your interests and rights or freedoms, given the
              regular reviews and related documentation of the processing
              activities described herein, the protection of your personal data
              by our data privacy processes or the transparency we provide on
              the processing activity and the rights you have in relation to the
              processing activity. If you wish to obtain further information on
              this balancing test approach please contact our data privacy
              organization at gdpr [at] ifm.com.
            </li>
            <li>
              In some cases we ask you to consent to the relevant use of your
              personal data. Then the legal basis is that you have consented
              (Art. 6 Abs. 1 lit. a GDPR – consent).
            </li>
          </ul>
          <h2>
            c) Processing of personal data in the context of our online services
            – purpose and legal basis
          </h2>
          <p>
            aa) To provide our online services and functions including creating
            and administering your online account, updating, securing and
            troubleshooting, providing support as well as improving and
            developing our online services
          </p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>bb)To bill your use of our online services</p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>cc) To identify you</p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>dd) To answer and fulfill your requests or instructions</p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            ee) To process your order or to provide you with access to specific
            information or offers
          </p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            ff) To send you marketing information or to contact you in the
            context of customer satisfaction surveys
          </p>
          <ul>
            <li>
              consent, if voluntarily provided (Art. 6 Abs. 1 lit. a GDPR)
            </li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            gg) As reasonably necessary to enforce our online services terms, to
            establish or preserve a legal claim or defense, to prevent fraud or
            other illegal activities, including attacks on our information
            technology systems
          </p>
          <ul>
            <li>
              compliance with legal obligations (Art. 6 Abs. 1 lit. c GDPR)
            </li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <h2>
            d) Processing of personal data related to your business relationship
            with us – purpose and legal basis
          </h2>
          <p>
            aa) Communicating with our business partners about our products,
            services and projects by responding to inquiries or requests or
            providing you with technical information about purchased products
          </p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            bb) Planning, performing and managing the (contractual) relationship
            with our business partners by performing transactions and orders of
            products or services, processing payments, performing accounting,
            auditing, billing and collection activities, arranging shipments and
            deliveries, facilitating repairs and providing support services
          </p>
          <ul>
            <li>contract performance (Art. 6 Abs. 1 lit. b GDPR)</li>
            <li>
              compliance with legal obligations (Art. 6 Abs. 1 lit. c GDPR)
            </li>
          </ul>
          <p>
            cc) Administrating and performing market analysis, sweepstakes,
            contests, or other customer activities or events
          </p>
          <ul>
            <li>
              consent, if voluntarily provided (Art. 6 Abs. 1 lit. a GDPR)
            </li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            dd) Conducting customer satisfaction surveys and direct marketing
            activities
          </p>
          <ul>
            <li>
              consent, if voluntarily provided (Art. 6 Abs. 1 lit. a GDPR)
            </li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            ee) Maintaining and protecting the security of our products,
            services and websites, preventing and detecting security threats,
            fraud or other criminal or malicious activities
          </p>
          <ul>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            ff) Ensuring compliance with legal obligations (like record keeping
            obligations), export control and customs, our business partners
            compliance screening obligations (to prevent white-collar or money
            laundering crimes) and our policies or industrial standards
          </p>
          <ul>
            <li>
              compliance with legal obligations (Art. 6 Abs. 1 lit. c GDPR)
            </li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <p>
            gg) Solving disputes, enforce our contractual agreements and to
            establish, exercise or defend legal claims
          </p>
          <ul>
            <li>
              compliance with legal obligations (Art. 6 Abs. 1 lit. c GDPR)
            </li>
            <li>legitimate interest (Art. 6 Abs. 1 lit. f GDPR)</li>
          </ul>
          <h2>e) International data transfers</h2>
          <p>
            In the event that we transfer your personal data outside the
            European Economic Area, we ensure that your data is protected in a
            manner which is consistent with the GDPR. Therefore we transfer
            personal data to external recipients outside the European Economic
            Area only if the recipient has entered into EU Standard Contractual
            Clauses with us or implemented Binding Corporate Rules in its
            organization. You may request further information about the
            safeguards implemented in relation to specific transfers (data
            transfer impact assessment) by contacting gdpr [at] ifm.com.
          </p>
          <h2>f) Your data protection authority</h2>
          <p>
            You always have the right to approach your data protection authority
            with your request or complaint. In case of data privacy related
            concerns and requests we kindly ask you to contact our data privacy
            organization at gdpr [at] ifm.com.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="10. Further information for U.S. residents">
        <PrivacyNoticeText>
          <p>
            If you are a U.S. resident, then please take note of the following:
          </p>
          <h2>a) Do Not Track</h2>
          <p>
            At this time our online services do not recognize or respond to “Do
            Not Track” browser signals. For more information on “Do Not Track”
            please visit your browsers support page.
          </p>
          <h2>b) Usage by children</h2>
          <p>
            This online service is not directed to children under the age of
            thirteen. We will not knowingly collect personal data from children
            under the age of thirteen without insisting that they seek prior
            parental consent if required by applicable law. We will only use or
            disclose personal data about a child to the extent permitted by law,
            to seek parental consent, pursuant to local law and regulations or
            to protect a child.
          </p>
          <h2>c) State rights</h2>
          <p>
            Depending on the U.S. state in which you reside, you may have
            special rights with respect to your personal data. For information
            regarding any of those rights, please inform yourself from relevant
            sources.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="11. Processing according to General Data Protection Law (Brazil)">
        <PrivacyNoticeText>
          <p>
            This section applies and provides you with further information if
            the processing by one of our companies occurs in Brazilian
            territory, concerns the data of individuals located in Brazilian
            territory, comprises personal data collected in Brazilian territory
            or has as its objective the offer or supply of goods or services to
            individuals located in Brazilian territory. In these cases the
            Brazilian General Data Protection Law (Lei Geral de Proteção de
            Dados – LGPD) applies to the processing of your personal data and
            the following additions or deviations of this data privacy notice:
          </p>
          <h2>a) Retention periods</h2>
          <p>
            As allowed under article 16 of LGPD we may retain your personal data
            to comply with legal or regulatory obligations (such as retention
            obligations due to tax law or commercial law), during the legal
            statute of limitation period or for the regular exercise of rights
            in judicial, administrative or arbitration proceedings.
          </p>
          <h2>b) Your rights</h2>
          <p>
            Additionally to the rights mentioned in this Data Privacy Notice,
            you are entitled under LGPD to:
          </p>
          <ul>
            <li>
              In case you understand your data is not being processed in
              accordance with the applicable data protection law or in an
              excessive way, request us to anonymize, block or delete
              unnecessary or excessive personal data
            </li>
            <li>
              Request information regarding the public or private entities we
              shared your personal data with
            </li>
            <li>
              Be informed about the possibility of not giving your consent to
              process your data and the consequences of not giving the consent
              in case we request your consent to process your data
            </li>
            <li>
              Revoke at any time your consent to our processing of your personal
              data in case we request your consent to process your data
            </li>
          </ul>
          <h2>c) Legal basis of the processing</h2>
          <p>
            The Brazilian General Data Protection Law requires us to provide you
            with information on the legal basis of the processing of your
            personal data. The legal basis for our processing is:
          </p>
          <ul>
            <li>Article 7 V LGPD (Contract Performance)</li>
            <li>Article 7 II LGPD (Compliance with Legal Obligations)</li>
            <li>Article 10 I and II LGPD (Legitimate Interest)</li>
            <li>Article 7 I LGPD (Consent)</li>
          </ul>
          <h2>d) International transfers</h2>
          <p>
            Following the LGPD requirements defined in the Article 33 of
            Brazilian General Data Protection Law, in the event that we transfer
            your personal data outside the Brazilian territory, we ensure that
            your data is protected in a manner which is consistent with the
            Brazilian General Data Protection Law, we will follow the applicable
            law and decisions imposed by the proper authority.
          </p>
          <h2>e) Data Protection contact</h2>
          <p>
            If this section applies, you may contact ifm Brazil under info.br
            [at] ifm.com or the data privacy organization of ifm under gdpr [at]
            ifm.com.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="12. Processing according to Protection of Personal Information Act (South Africa)">
        <PrivacyNoticeText>
          <p>
            For our business partners and users located in South Africa, please
            take note of the following:
          </p>
          <p>
            In terms of section 1 of the Protection of Personal Information Act,
            2013 (“POPI”), “personal data” or “personal information” includes
            “information relating to an identifiable, living, natural person,
            and where it is applicable, an identifiable, existing, juristic
            person.”
          </p>
          <p>
            The corresponding legal grounds and conditions for lawful processing
            of personal data in South Africa are contained in Sections 8 to 25
            of POPI, and relate to “Accountability”; “Processing limitation”;
            “Purpose specification”; “Further processing limitation”;
            “Information quality”; “Openness”; “Security safeguards” and “Data
            subject participation”.
          </p>
          <p>
            In terms of section 69 of POPI, the processing of personal
            information of a data subject for the purposes of direct marketing
            by means of any form of electronic communication, including
            automatic calling machines, facsimile machines, sms or email is
            prohibited unless the data subject has provided consent to the
            processing, or is, subject to further conditions, an existing
            customer of the responsible party.
          </p>
          <p>
            For purposes of a Data Subject exercising its rights further
            enquiries and the exercise of its rights in relation to access,
            objection to, and complaints in respect of the processing of
            personal data, the contact particulars of the Information Regulator
            of South Africa, are as follows:
          </p>
          <p>JD House, 27 Stiemens Street, Braamfontein, Johannesburg, 2001</p>
          <p>PO Box 31533, Braamfontein, Johannesburg, 2017</p>
          <p>Complaints: complaints.IR@justice.gov.za</p>
          <p>General enquiries: inforeg@justice.gov.za</p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="13. Processing according to PIPL (People’s Republic of China)">
        <PrivacyNoticeText>
          <p>
            This section applies and provides you with further information if
            the processing by one of our companies is located within the borders
            of People’s Republic of China (“PRC”) or concerns the data of
            individuals within the borders of PRC.
          </p>
          <h2>a) Processing of sensitive personal information</h2>
          <p>
            According to the PIPL, sensitive personal information means personal
            information that, once leaked or illegally used, may easily cause
            harm to the dignity of natural persons grave harm to personal or
            property security, including information on biometric
            characteristics, religious beliefs, specially-designated status,
            medical health, financial accounts, individual location tracking,
            etc. as well as the personal information of minors under the age of
            14.
          </p>
          <p>
            In addition to the payment data mentioned in this data private
            notice, we will, in principle, not process your sensitive personal
            information. In case your sensitive personal information will be
            proceed, we will notify you about the necessity of processing and
            effects on the individual’s rights and interests, and obtain your
            specific consent if applicable.
          </p>
          <h2>b) Transfer and disclosure of personal data</h2>
          <p>
            Following the requirements defined in the Article 23 of PIPL,
            additionally to the contents mentioned in section 4, we, in
            principle, will not transfer or share your personal information to
            third party controllers, unless obtain your specific consent if
            applicable or to fulfill the statutory duties under local laws and
            regulations.
          </p>
          <h2>c) International Transfer</h2>
          <p>
            You acknowledge that your data will be transferred and proceed
            outside of PRC. We will follow the applicable laws and decisions
            imposed by the competent authority and ensure that your data is
            protected in a manner which is consistent with the PRC Personal
            Information Protection Law. If you or the company you work for is
            our business partner, please be aware that ifm is a group of
            companies based in Germany and different other countries worldwide
            and for the purpose of concluding or fulfilling the
            contract/agreement with you or the company you work for, you
            understand and agree that we may transfer your personal information
            to foreign affiliated companies.
          </p>
          <h2>d) Legal Basis of the processing</h2>
          <p>
            The PIPL requires us to provide you with information on the legal
            basis of the processing of your personal data. The legal basis for
            our processing is:
          </p>
          <ul>
            <li>PIPL Article 13(2) (Contract Performance)</li>
            <li>PIPL Article 13(3) (Statutory duties and responsibilities)</li>
            <li>PIPL Article 13(6) (Process publicly available data)</li>
            <li>PIPL Article 13(1) (Consent)</li>
          </ul>
          <h2>e) Usage by Children</h2>
          <p>
            Our online services are not directed to children under the age of
            fourteen (14). We will not knowingly collect personal data from
            children under the age of fourteen (14) without prior parental
            consent if required by applicable law. We will only use or disclose
            personal data about a child to the extent permitted by law, to seek
            parental consent, pursuant to local law and regulations or to
            protect a child.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
      <PrivacyNoticeHeader text="14. Amendment of this Privacy Notice">
        <PrivacyNoticeText>
          <p>
            We reserve the right to amend this data privacy notice any time with
            future effect. The current version can be accessed on our websites.
            Please visit our websites regularly and inform yourself about the
            provisions on data protection in effect.
          </p>
        </PrivacyNoticeText>
      </PrivacyNoticeHeader>
    </>
  );
};

export default PrivacyNoticeContent;
