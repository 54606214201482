import {
  Grid,
  Button,
  Typography,
  Chip,
  Card,
  CardContent
} from '@mui/material';
import AdminSectionHeader from '../components/Admin/AdminSectionHeader';
import { useContext, useState } from 'react';
import EditModal from '../components/Admin/EditModal';
import styled from 'styled-components';
import { iconFromReleaseNoteType } from '../components/StatusIcon';
import ReleaseNoteForm from '../components/Admin/Forms/ReleaseNoteForm';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import {
  IComponent,
  IComponentGroup,
  IMarkdownTemplate,
  IReleaseNote
} from '../interfaces';
import ReactMarkdown from 'react-markdown';
import { collapseLinebreaks } from '../utils';
import SnackbarContext from '../contexts/SnackbarContext';
import MarkdownForm from '../components/Admin/Forms/MarkdownForm';

interface IAdminReleaseNotePage {
  releaseNotes: Array<IReleaseNote>;
  markdownTemplates: Array<IMarkdownTemplate>;
  fetchReleaseNotes: Function;
  fetchMarkdownTemplates: Function;
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
}

const AdminReleaseNotePage = ({
  releaseNotes,
  markdownTemplates,
  fetchMarkdownTemplates,
  fetchReleaseNotes,
  components,
  componentGroups
}: IAdminReleaseNotePage) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<any>(null);
  const { handleResponse } = useContext(SnackbarContext);

  const handleReleaseNotesCallback = () => {
    setModalOpen(false);
    fetchReleaseNotes();
  };

  const handleDeleteReleaseNote = async (id: string) => {
    try {
      const response = await fetch('/api/v1/release_notes/' + id, {
        method: 'DELETE',
        headers: AuthenticationService.instance.headers
      });

      handleResponse(
        response,
        'release note',
        'delete',
        handleReleaseNotesCallback
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddOrUpdateReleaseNote = (releaseNote?: IReleaseNote) => {
    setFormContent(
      <ReleaseNoteForm
        setModalOpen={setModalOpen}
        fetchReleaseNotes={fetchReleaseNotes}
        releaseNote={releaseNote}
        markdownTemplate={markdownTemplates[0]}
        components={components}
        componentGroups={componentGroups}
      />
    );
    setModalOpen(true);
  };

  const handleAddOrUpdateMarkdownTemplate = (
    markdownTemplate?: IMarkdownTemplate
  ) => {
    setFormContent(
      <MarkdownForm
        setModalOpen={setModalOpen}
        fetchMarkdownTemplates={fetchMarkdownTemplates}
        markdownTemplate={markdownTemplate}
      />
    );
    setModalOpen(true);
  };

  return (
    <div>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={formContent}
      />
      <AdminSectionHeader
        title="Release Notes"
        handleClick={() => handleAddOrUpdateReleaseNote()}
        handleClickButtonLeft={() =>
          handleAddOrUpdateMarkdownTemplate(markdownTemplates[0])
        }
        buttonText="Add new release note"
        buttonLeftText="Update Markdown Template"
      ></AdminSectionHeader>
      {releaseNotes.map((rn) => {
        return (
          <div key={rn.id}>
            <CardStyled boxshadow="true">
              <CardContentStyled padding={'1em'}>
                <Grid container>
                  <Grid item>{iconFromReleaseNoteType(rn.type)}</Grid>
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ marginBottom: '5px' }}
                    >
                      {rn.name}
                    </Typography>
                  </Grid>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ marginRight: '10px' }}
                    >
                      {rn.version_tag ? rn.version_tag : ''}
                    </Typography>
                  </Grid>
                </Grid>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Grid container>
                      {(rn.groups ?? [])
                        .concat((rn.components ?? []) as Array<any>)
                        .map((c: any) => {
                          return (
                            <Chip
                              label={c.name}
                              sx={{
                                border: '#7295C2',
                                background: '#7295C280',
                                color: '#ffffff',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                marginRight: '20px',
                                marginBottom: '5px'
                              }}
                              key={c.id}
                            />
                          );
                        })}
                    </Grid>

                    <div style={{ marginTop: '5px' }}>
                      <ReactMarkdown>
                        {collapseLinebreaks(rn.message)}
                      </ReactMarkdown>
                    </div>
                  </div>
                  <div style={{ marginTop: 'auto', minWidth: '180px' }}>
                    <Button
                      variant="outlined"
                      style={{ marginRight: '10px' }}
                      onClick={() => handleAddOrUpdateReleaseNote({ ...rn })}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleDeleteReleaseNote(rn.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
                <CardFooterContainer>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      {rn.created_at
                        ? new Date(rn.created_at).toLocaleString()
                        : ''}
                    </Grid>
                  </Grid>
                </CardFooterContainer>
              </CardContentStyled>
            </CardStyled>
          </div>
        );
      })}
    </div>
  );
};

export default AdminReleaseNotePage;

const CardStyled = styled(Card)<any>`
  box-shadow: ${({ boxshadow }) =>
    boxshadow
      ? '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important'
      : 'none !important'};
  border-radius: 0 !important;
  margin-bottom: 20px;
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #414141;
  ${({ padding }) => ('padding: ' + padding ? padding : '0px !important')};
  color: #ffffff;
`;

const CardFooterContainer = styled.div`
  background-color: #414141;
  margin-top: 20px;
  color: #ffffffb3;
  padding: 0px !important;
`;
