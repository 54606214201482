import styled from 'styled-components';
import SectionHeader from '../SectionHeader';
import { Grid } from '@mui/material';
import { getIncidentStatus, iconFromIncidentStatus } from '../StatusIcon';
import { StyledIncidentIconContainer } from '../CurrentIncidentView';
import IncidentCard from '../IncidentCard';

const Container = styled.div``;

interface IScheduledMaintenanceView {
  scheduledMaintenances: Array<any>;
}

const ScheduledMaintenanceView: React.FC<IScheduledMaintenanceView> = ({
  scheduledMaintenances
}: IScheduledMaintenanceView) => {
  const today = new Date();
  return (
    <div>
      <Container>
        <SectionHeader title="Scheduled Maintenances"></SectionHeader>
        <Grid container spacing={3} sx={{ paddingRight: '2em' }}>
          {scheduledMaintenances ? (
            scheduledMaintenances
              .filter((i) => {
                const status = getIncidentStatus(i);
                const scheduledFrom = new Date(
                  i.scheduled_maintenance.scheduledFrom ?? 0
                );
                const scheduledTo = new Date(
                  i.scheduled_maintenance.scheduledTo ?? Number.MAX_VALUE
                );
                return (
                  (status === 0 || status === 1) &&
                  (scheduledFrom.getTime() > today.getTime() ||
                    scheduledTo.getTime() < today.getTime())
                );
              })
              .map((i) => {
                return (
                  <Grid item container xs={12} lg={6} spacing={2} key={i.id}>
                    <Grid
                      item
                      container
                      xs={1}
                      justifyContent="flex-end"
                      alignItems="top"
                    >
                      <StyledIncidentIconContainer>
                        {iconFromIncidentStatus(getIncidentStatus(i))}
                      </StyledIncidentIconContainer>
                      <Grid item xs={11}></Grid>
                    </Grid>
                    <Grid item xs>
                      <IncidentCard
                        key={i.id}
                        name={i.name}
                        message={i.message}
                        created_at={i.created_at}
                        incident_updates={i.incident_updates}
                        components={i.components}
                        groups={i.groups}
                        padding="1em"
                        boxshadow={true}
                        scheduledFrom={i.scheduledFrom}
                        scheduledTo={i.scheduledTo}
                      ></IncidentCard>
                    </Grid>
                  </Grid>
                );
              })
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ScheduledMaintenanceView;
