import { ClickAwayListener, Collapse, List, Popper } from '@mui/material';
import ListItems from './ListItems';
import { IComponentGroup } from '../../../interfaces';

interface ICheckBoxList {
  handleClickAway: () => void;
  isExtended: boolean;
  anchorEl: Element | null;
  groups: Array<IComponentGroup>;
  filterBy: Array<string>;
  handleCheckBoxClick: Function;
}

const popperStyles = {
  '.MuiPopover-paper': {
    borderRadius: '1px'
  },
  '.MuiPaper-root': {
    backgroundColor: '#707070 !important'
  },
  '&$checked': {
    color: '#3D70B2'
  },
  maxHeight: '80%',
  overflow: 'auto',
  boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.12)'
};

const CheckBoxList = ({
  handleClickAway,
  isExtended,
  anchorEl,
  groups,
  filterBy,
  handleCheckBoxClick
}: ICheckBoxList) => {
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Collapse in={isExtended}>
        <Popper
          open={isExtended}
          anchorEl={anchorEl}
          sx={popperStyles}
          placement="bottom-start"
        >
          <List
            component="div"
            disablePadding
            onClick={(e: any) => e.stopPropagation()}
            sx={{
              backgroundColor: '#555555',
              border: '1px solid #707070'
            }}
          >
            <ListItems
              groups={groups}
              filterBy={filterBy}
              handleCheckBoxClick={handleCheckBoxClick}
            />
          </List>
        </Popper>
      </Collapse>
    </ClickAwayListener>
  );
};

export default CheckBoxList;
