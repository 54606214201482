import { Button, CircularProgress } from '@mui/material';

interface ILoadingEditButton {
  onClick: () => void;
  loadingEdit: boolean;
  text: string;
  disabled?: boolean;
}

const LoadingEditButton = ({
  onClick,
  loadingEdit,
  text,
  disabled
}: ILoadingEditButton) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled || loadingEdit}
    >
      {loadingEdit ? (
        <CircularProgress
          sx={{
            margin: 'auto',
            scale: 0.1
          }}
        />
      ) : (
        <>{text}</>
      )}
    </Button>
  );
};

export default LoadingEditButton;
