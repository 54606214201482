import { AuthenticationService } from '../../services/Authentication/AuthenticationService';
import { useNavigate } from 'react-router-dom';

const WithAuthentication = ({ children }: any) => {
  const authService = AuthenticationService.instance;
  let navigate = useNavigate();

  if (authService.hasAccess) {
    return children;
  } else {
    navigate('/login');
  }
};

export default WithAuthentication;
