import { AlertColor } from '@mui/material';
import React from 'react';

export interface ISnackbarContext {
  snackOpen: boolean;
  setSnackOpen: (x: boolean) => void;
  snackMessage: string;
  setSnackMessage: (x: string) => void;
  snackSeverity: AlertColor | undefined;
  setSnackSeverity: (x: AlertColor | undefined) => void;
  handleResponse: (
    response: Response,
    name: string,
    actionName: 'add' | 'edit' | 'delete',
    callback: () => void
  ) => void;
}

export default React.createContext<ISnackbarContext>({} as any);
