import { ReactNode, useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';

interface IAreYouSureButton {
  onClick: () => void;
  children: ReactNode;
  [x: string]: any; // button props
}

const AreYouSureButton = ({
  onClick,
  children,
  ...props
}: IAreYouSureButton) => {
  const confirmationRef = useRef(false);
  const [askingConfirmation, setAskingConfirmation] = useState<boolean>(false);

  useEffect(() => {
    confirmationRef.current = askingConfirmation;
  }, [askingConfirmation]);

  const handleClick = () => {
    if (!askingConfirmation) {
      setAskingConfirmation(true);
      setTimeout(() => {
        if (confirmationRef.current) {
          setAskingConfirmation(false);
        }
      }, 5000);
    } else {
      setAskingConfirmation(false);
      onClick();
    }
  };

  return (
    <Button
      {...props}
      onClick={handleClick}
      color={askingConfirmation ? 'error' : 'primary'}
    >
      {askingConfirmation ? 'Are you sure?' : children}
    </Button>
  );
};

export default AreYouSureButton;
