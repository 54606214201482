import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import PrivacyNoticeContent from '../components/PrivacyNotice/PrivacyNoticeContent';
import PrivacyNoticeText from '../components/PrivacyNotice/PrivacyNoticeText';
import SectionHeader from '../components/SectionHeader';
import StickyFooter from '../components/StickyFooter';
import StatuspageAppBar from '../components/StatuspageAppBar';

const MarginP = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

const PrivacyNoticePage: React.FC<{}> = () => {
  return (
    <>
      <StatuspageAppBar onAdminPage={false} />
      <Grid container>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <SectionHeader title="Data Privacy Notice"></SectionHeader>
          <PrivacyNoticeText>
            <Typography variant="h6">ifm cloud services statuspage</Typography>
            <p>ifm electronic gmbh</p>
            <p>Friedrichstr. 1</p>
            <p>D-45128 Essen</p>
            <p>phone: +49 2 01 / 24 22-0</p>
            <p>email: info@ifm.com</p>
            <p>ifm – group of companies</p>
            <MarginP>
              Thank you for your interest in our services and products. We
              respect your privacy and handle your data responsibly. This
              privacy notice explains how we collect, store, use, disclose and
              transfer (all: process) your personal data. The personal data that
              we collect about you depends on the context of your interaction
              with us: the products, services and features that you use, your
              location and applicable law.
            </MarginP>
          </PrivacyNoticeText>
          <PrivacyNoticeContent></PrivacyNoticeContent>
          <MarginP></MarginP>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>
      <StickyFooter></StickyFooter>
    </>
  );
};

export default PrivacyNoticePage;
