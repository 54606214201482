import { Alert, Snackbar, AlertColor } from '@mui/material';
import React from 'react';

interface IFormSnackbar {
  snackOpen: boolean;
  snackMessage: string;
  snackSeverity: AlertColor | undefined;
  setSnackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormSnackbar = ({
  snackOpen,
  snackMessage,
  snackSeverity,
  setSnackOpen
}: IFormSnackbar) => {
  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={6000}
      onClose={() => setSnackOpen(false)}
      sx={{ marginBottom: '70px' }}
    >
      <Alert severity={snackSeverity} onClose={() => setSnackOpen(false)}>
        {snackMessage}
      </Alert>
    </Snackbar>
  );
};

export default FormSnackbar;
