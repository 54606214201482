import { Typography, Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import ExpandableTextbox from './FormComponents/ExpandableTextbox';
import StatusSelect, { IconType } from './FormComponents/StatusSelect';
import LoadingEditButton from './FormComponents/LoadingEditButton';
import useValidation from '../../../hooks/useValidation';
import { IIncident, IIncidentUpdate } from '../../../interfaces';
import { v4 as uuidv4 } from 'uuid';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IIncidentUpdateForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchIncidents: any;
  incident: IIncident;
  update?: IIncidentUpdate;
}

const IncidentUpdateForm = ({
  setModalOpen,
  fetchIncidents,
  incident,
  update
}: IIncidentUpdateForm) => {
  const [message, setMessage] = useState<string>(update?.message ?? '');
  const [status, setStatus] = useState<number>(update?.status ?? 1);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const validateMessage = (message: string | null) => {
    return !!message;
  };
  const [messageValid] = useValidation(validateMessage, message);

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchIncidents();
  };

  const handleEditIncidentUpdate = async () => {
    if (!update?.id) {
      setSnackMessage('Error: incident update id not given');
      setSnackSeverity('error');
      setSnackOpen(true);
      return;
    }

    if (messageValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/incidents/updates/' + update.id, {
          method: 'PUT',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            status,
            message,
            updated_at: new Date()
          })
        });
        setLoadingEdit(false);

        handleResponse(response, 'incident update', 'add', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleAddIncidentUpdate = async () => {
    if (messageValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch(
          '/api/v1/incidents/' + incident.id + '/updates',
          {
            method: 'POST',
            headers: AuthenticationService.instance.headers,
            body: JSON.stringify({
              id: uuidv4(),
              status,
              message,
              created_at: new Date(),
              updated_at: null
            })
          }
        );
        setLoadingEdit(false);

        handleResponse(response, 'incident update', 'edit', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Edit Update
        </Typography>
      </div>
      <div>
        <ExpandableTextbox
          defaultMessage={update?.message ?? ''}
          message={message ?? ''}
          setMessage={setMessage}
        />
        <StatusSelect
          setStatus={setStatus}
          status={status}
          iconType={IconType.IncidentStatus}
        />
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingEditButton
            onClick={() =>
              update?.id
                ? handleEditIncidentUpdate()
                : handleAddIncidentUpdate()
            }
            loadingEdit={loadingEdit}
            text={(update?.id ? 'Edit' : 'Add') + ' Update'}
            disabled={!messageValid}
          ></LoadingEditButton>
        </div>
      </div>
    </div>
  );
};

export default IncidentUpdateForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
