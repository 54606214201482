import styled from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 6px 10vw;
  height: 50px;
  background-color: #414141;
  z-index: 2;
`;

const FooterLink = styled.a`
  font-size: 18px;
  text-decoration: none;
  padding: 0px 10px;
`;

const FooterTextBottomGrey = styled.p`
  margin-left: auto;
  color: #ffffffb3;
  font-size: 14px !important;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>
        <FooterLink
          href="https://www.ifm.com/de/en/de/footer/legal-notice"
          rel="noopener noreferrer"
          target="_blank"
        >
          Legal Notice
        </FooterLink>
      </p>

      <p>
        <FooterLink
          href="https://www.ifm.com/de/en/de/terms-of-service"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </FooterLink>
      </p>

      <p>
        <FooterLink href="/privacy-notice" rel="noopener noreferrer">
          Privacy Policy
        </FooterLink>
      </p>

      <p>
        <FooterLink
          href="https://www.ifm.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Online Shop
        </FooterLink>
      </p>

      <FooterTextBottomGrey>© ifm electronic gmbh</FooterTextBottomGrey>
    </FooterContainer>
  );
};

export default Footer;
