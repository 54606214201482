import { Button, TextField, Typography, styled } from '@mui/material';
import { IUser } from '../../../interfaces';
import LoadingEditButton from './FormComponents/LoadingEditButton';
import { useContext, useMemo, useState } from 'react';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import useValidation from '../../../hooks/useValidation';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IUserForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchUsers: () => Promise<any>;
  user?: IUser;
}

const UserForm = ({ setModalOpen, fetchUsers, user }: IUserForm) => {
  const [email, setEmail] = useState<string | null>(user?.email ?? null);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchUsers();
  };

  function validateEmail(email: string | null) {
    if (!email) return false;
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function validatePassword(
    password: string | null,
    confirmPassword: string | null
  ) {
    if (!password || !confirmPassword) return false;
    return password === confirmPassword;
  }

  const [emailValid, showEmailValid, updateEmailDisplay] = useValidation(
    validateEmail,
    email
  );

  const [passwordValid, showPasswordValid, updatePasswordDisplay] =
    useValidation(validatePassword, password, confirmPassword);

  const buttonActive = useMemo(() => {
    return (
      (user?.id && emailValid && (!password || passwordValid)) ||
      (emailValid && passwordValid)
    );
  }, [passwordValid, emailValid, password, user]);

  const handleAddUser = async () => {
    if (emailValid && passwordValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/users/', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            email,
            password
          })
        });
        setLoadingEdit(false);

        handleResponse(response, 'user', 'add', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleUpdateUser = async () => {
    if (!user) {
      console.error('No user set');
      setSnackMessage('No user set');
      setSnackSeverity('error');
      setSnackOpen(true);
      return;
    }
    if (emailValid && (passwordValid || !password)) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/users/' + user?.id, {
          method: 'PUT',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            email,
            ...(password ? { password } : {})
          })
        });
        setLoadingEdit(false);

        handleResponse(response, 'user', 'edit', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          {user?.id ? 'Edit' : 'Add'} User
        </Typography>
      </div>

      <div>
        <TextFieldStyled
          {...(showEmailValid
            ? { error: false }
            : {
                error: true,
                helperText: 'This field is required and must be an email.'
              })}
          onBlur={() => updateEmailDisplay()}
          label="Email"
          id="email"
          size="small"
          fullWidth
          defaultValue={user?.email ?? ''}
          InputProps={{ style: { color: 'white' } }}
          onChange={(e: any) => setEmail(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <TextFieldStyled
          {...(showPasswordValid
            ? { error: false }
            : { error: true, helperText: 'Passwords must match.' })}
          onBlur={() => updatePasswordDisplay()}
          label="Password"
          id="password"
          type="password"
          size="small"
          fullWidth
          defaultValue={''}
          InputProps={{ style: { color: 'white' } }}
          onChange={(e: any) => setPassword(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <TextFieldStyled
          {...(showPasswordValid
            ? { error: false }
            : { error: true, helperText: 'Passwords must match.' })}
          onBlur={() => updatePasswordDisplay()}
          label="Confirm password"
          id="confirmPassword"
          type="password"
          size="small"
          fullWidth
          defaultValue={''}
          InputProps={{ style: { color: 'white' } }}
          onChange={(e: any) => setConfirmPassword(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />

        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingEditButton
            onClick={() => (user?.id ? handleUpdateUser() : handleAddUser())}
            loadingEdit={loadingEdit}
            text={(user?.id ? 'Edit' : 'Add') + ' User'}
            disabled={!buttonActive}
          ></LoadingEditButton>
        </div>
      </div>
    </div>
  );
};

export default UserForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
