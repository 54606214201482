import {
  TextField,
  Typography,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
  ListItemText,
  Grid
} from '@mui/material';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { IComponent, IComponentGroup } from '../../../interfaces';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import StatusSelect, { IconType } from './FormComponents/StatusSelect';
import useValidation from '../../../hooks/useValidation';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IComponentForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchComponents: any;
  component?: IComponent;
  componentGroups: Array<IComponentGroup>;
}

const ComponentForm = ({
  setModalOpen,
  componentGroups,
  fetchComponents,
  component
}: IComponentForm) => {
  let initialGroupIds = component?.groups?.length
    ? component.groups.map((g: any) => g.id)
    : [];
  const [name, setName] = useState<string>(component?.name ?? '');
  const [groupIds, setGroupIds] = useState<Array<string>>(initialGroupIds);
  const [trigger_incident_automatically, setTrigger_incident_automatically] =
    useState<boolean>(component?.trigger_incident_automatically ?? false);
  const [status, setStatus] = useState<number>(component?.status ?? -1);

  const handleGroupSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setGroupIds(typeof value === 'string' ? value.split(',') : value);
  };

  const validateName = (name: string | null) => {
    return !!name;
  };
  const [nameValid, showNameValid, updateNameDisplay] = useValidation(
    validateName,
    name
  );
  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchComponents();
  };

  const handleAddComponent = async () => {
    if (nameValid) {
      try {
        const response = await fetch('/api/v1/components', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            id: uuidv4(),
            name,
            group_ids: groupIds,
            enabled: true,
            status,
            trigger_incident_automatically,
            manual: true
          })
        });

        handleResponse(response, 'component', 'add', responseCallback);
      } catch (e) {
        console.error(e);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleEditComponent = async () => {
    if (!component?.id) {
      setSnackMessage('Component Id not specified');
      setSnackSeverity('error');
      setSnackOpen(true);
      return;
    }

    if (nameValid) {
      try {
        const response = await fetch('/api/v1/components/' + component.id, {
          method: 'PUT',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            name,
            group_ids: groupIds,
            enabled: true,
            status,
            trigger_incident_automatically,
            manual: true
          })
        });

        handleResponse(response, 'component', 'edit', responseCallback);
      } catch (e) {
        console.error(e);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Edit Component
        </Typography>
      </div>

      <div>
        <TextFieldStyled
          {...(showNameValid
            ? { error: false }
            : { error: true, helperText: 'This field is required' })}
          onBlur={() => updateNameDisplay()}
          label="Name*"
          id="name"
          size="small"
          defaultValue={component?.name ?? ''}
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          onChange={(e) => setName(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <StatusSelect
          iconType={IconType.ComponentStatus}
          setStatus={setStatus}
          status={status}
        />

        <Grid container alignItems="center" sx={{ margin: '10px' }}>
          <h3>Trigger incidents automatically on status change?</h3>
          <Checkbox
            checked={trigger_incident_automatically}
            onChange={(event) =>
              setTrigger_incident_automatically(event.target.checked)
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>

        <FormControl
          sx={{
            marginTop: '20px',
            color: '#b7b7b7',
            svg: { color: '#b7b7b7' }
          }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="groupLabel"
            sx={{
              color: '#b7b7b7'
            }}
          >
            Select component group
          </InputLabel>
          <Select
            labelId="componentsSelectLabel"
            id="componentsSelect"
            multiple
            value={groupIds}
            onChange={(event) => handleGroupSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value: string) => (
                  <Chip
                    label={
                      componentGroups.find((g: any) => g.id === value)?.name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {componentGroups.map((c: any) => (
              <MenuItem key={c.id} value={c.id}>
                <Checkbox checked={groupIds.indexOf(c.id) > -1} />
                <ListItemText primary={c.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body2" color="#b7b7b7" sx={{ marginTop: '8px' }}>
          *required fields
        </Typography>
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              component?.id ? handleEditComponent() : handleAddComponent();
            }}
            disabled={!nameValid}
          >
            {component?.id ? 'Save' : 'Add Component'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ComponentForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
