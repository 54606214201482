import { TextField, Typography, Button, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import styled from 'styled-components';

interface ISubscriptionForm {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionForm = ({ modalOpen, setModalOpen }: ISubscriptionForm) => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
  let emailValid = true;

  const validateEmail = (email: string) => {
    const regex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line no-control-regex

    if (!regex.test(email)) {
      emailValid = false;
      setEmailIsValid(false);
    } else {
      emailValid = true;
      setEmailIsValid(true);
    }
  };

  const handleSubscribe = async () => {
    validateEmail(email);

    if (emailValid) {
      try {
        setLoading(true);

        const response = await fetch('/api/v1/subscribers', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email,
            component_ids: []
          })
        });

        setLoading(false);

        if (!response.ok) {
          console.error('Error adding subscriber');
          console.error('Response: ', response);
          setShowErrorAlert(true);
          setShowSuccessAlert(false);
        } else {
          setShowErrorAlert(false);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 2000);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Subscribe to status changes and incident updates
        </Typography>
      </div>
      {showSuccessAlert ? (
        <div style={{ marginTop: '10px' }}>
          <Alert severity="success" variant="filled">
            Awesome. We've sent you an email, please click the link to manage
            your subscription
          </Alert>
        </div>
      ) : showErrorAlert ? (
        <div style={{ marginTop: '10px' }}>
          <Alert severity="error" variant="filled">
            Something went wrong. Please try again later or contact our support.
          </Alert>
        </div>
      ) : (
        <div></div>
      )}

      <div>
        <TextFieldStyled
          {...(emailIsValid
            ? { error: false }
            : { error: true, helperText: 'Please enter a valid e-mail' })}
          onBlur={() => validateEmail(email)}
          label="E-mail"
          id="email"
          size="small"
          value={email}
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          onChange={(e: any) => setEmail(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => handleSubscribe()}
          >
            Subscribe
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
