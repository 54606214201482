import { Grid } from '@mui/material';
import styled from 'styled-components';
import { IIncident } from '../../interfaces';
import IncidentCard from '../IncidentCard';
import SectionHeader from '../SectionHeader';
import { getIncidentStatus, iconFromIncidentStatus } from '../StatusIcon';

interface ICurrentIncidentView {
  incidents: Array<IIncident>;
}

export const StyledIncidentIconContainer = styled.div`
  background: #555555;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
`;

const CurrentIncidentView = ({ incidents }: ICurrentIncidentView) => {
  const today = new Date();
  return (
    <div>
      <SectionHeader title="Current Incidents"></SectionHeader>
      <Grid container spacing={3} sx={{ paddingRight: '2em' }}>
        {incidents ? (
          incidents
            .filter((i) => {
              const status = getIncidentStatus(i);
              if (
                i.scheduled_maintenance &&
                i.scheduled_maintenance.id &&
                i.scheduled_maintenance.scheduledFrom &&
                i.scheduled_maintenance.scheduledTo
              ) {
                const scheduledFrom = new Date(
                  i.scheduled_maintenance.scheduledFrom
                );
                const scheduledTo = new Date(
                  i.scheduled_maintenance.scheduledTo
                );
                return (
                  [2, 3].includes(status) ||
                  (scheduledFrom.getTime() < today.getTime() &&
                    scheduledTo.getTime() > today.getTime())
                );
              } else {
                return [1, 2, 3].includes(status);
              }
            })
            .map((i) => {
              return (
                <Grid item container xs={12} lg={6} spacing={2} key={i.id}>
                  <Grid
                    item
                    container
                    xs={1}
                    justifyContent="flex-end"
                    alignItems="top"
                  >
                    <StyledIncidentIconContainer>
                      {iconFromIncidentStatus(getIncidentStatus(i))}
                    </StyledIncidentIconContainer>
                    <Grid item xs={11}></Grid>
                  </Grid>
                  <Grid item xs>
                    <IncidentCard
                      name={i.name}
                      message={i.message}
                      incident_updates={i.incident_updates}
                      created_at={i.created_at}
                      scheduledFrom={i.scheduled_maintenance?.scheduledFrom}
                      scheduledTo={i.scheduled_maintenance?.scheduledTo}
                      components={i.components}
                      groups={i.groups}
                      padding="1em"
                      boxshadow={true}
                    ></IncidentCard>
                  </Grid>
                </Grid>
              );
            })
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default CurrentIncidentView;
