import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  visibility: visible;
  opacity: 1;

  transition: opacity ease 0.4s;
`;

const CardImage = styled.img`
  max-width: 500px;
  z-index: 2;
`;

const CardImageLarge = styled.img`
  max-width: 80vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 901;
`;

const Image = ({
  id,
  base64encoding,
  ...props
}: {
  id: string;
  [props: string]: any;
}) => {
  const [click, setClick] = useState(false);

  const setFlag = () => {
    setClick(true);
  };

  const unsetFlag = () => {
    setClick(false);
  };

  const escFunction = useCallback((event: any) => {
    if (event.key === 'Escape') {
      setClick(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      {click ? (
        <Overlay onClick={unsetFlag}>
          <CardImageLarge src={base64encoding} {...props}></CardImageLarge>
        </Overlay>
      ) : (
        <></>
      )}
      <CardImage src={base64encoding} {...props} onClick={setFlag}></CardImage>
    </>
  );
};

export default Image;
