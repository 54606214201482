import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import ManageSubscriptionPage from './pages/ManageSubscriptionsPage';
import PrivacyNoticePage from './pages/PrivacyNoticePage';
import { useEffect, useState } from 'react';
import { AlertColor, Fab, styled } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SnackbarContext from './contexts/SnackbarContext';
import FormSnackbar from './components/Admin/Snackbars/FormSnackbar';

const ScrollToTopFAB = styled(Fab)`
  background-color: #ff9900;
  position: fixed !important;
  bottom: 8vh;
  right: 3vw;

  &:hover {
    background-color: #ff990099;
  }
`;

const ScrollToTopIcon = styled(ArrowUpwardIcon)`
  color: white;
  text-shadow: 2px 2px #ff0000;
`;

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [snackSeverity, setSnackSeverity] = useState<AlertColor | undefined>(
    undefined
  );

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const handleResponse = (
    response: Response,
    name: string,
    actionName: 'add' | 'edit' | 'delete',
    callback: () => void
  ) => {
    if (!response.ok) {
      console.error(`Error ${actionName}ing new ${name}`);
      console.error('Response', response);
      if (response.status === 401) {
        setSnackMessage(`Error ${actionName}ing ${name}: please log in`);
        window.open('/login', '_blank');
      } else {
        setSnackMessage(`Error ${actionName}ing new ${name}`);
      }
      setSnackSeverity('error');
      setSnackOpen(true);
    } else {
      setSnackMessage(`${name} was ${actionName}ed`);
      setSnackSeverity('success');
      setSnackOpen(true);
      callback();
    }
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackOpen,
        setSnackOpen,
        snackMessage,
        setSnackMessage,
        snackSeverity,
        setSnackSeverity,
        handleResponse
      }}
    >
      <FormSnackbar
        snackOpen={snackOpen}
        snackMessage={snackMessage}
        snackSeverity={snackSeverity}
        setSnackOpen={setSnackOpen}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/subscriptions" element={<ManageSubscriptionPage />} />
          <Route path="/privacy-notice" element={<PrivacyNoticePage />} />
        </Routes>
        {showTopBtn ? (
          <ScrollToTopFAB
            disableFocusRipple
            disableRipple
            onClick={() => goToTop()}
          >
            <ScrollToTopIcon />
          </ScrollToTopFAB>
        ) : (
          <></>
        )}
      </BrowserRouter>
    </SnackbarContext.Provider>
  );
}
export default App;
