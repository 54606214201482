function ServiceTile({ id }: { id: number }) {
  switch (id) {
    case 1: // moneo | cloud
      return (
        <img
          src={
            process.env.PUBLIC_URL +
            '/assets/service-icons/lettering-moneo-cloud-white.svg'
          }
          alt="Service Tile"
        />
      );
    case 2: // Cumulocity IoT
      return (
        <img
          src={
            process.env.PUBLIC_URL +
            '/assets/service-icons/cumulocity-cloud.svg'
          }
          alt="Service Tile"
        />
      );
    case 3: // Mobile IoT
      return (
        <img
          src={process.env.PUBLIC_URL + '/assets/service-icons/mobile-iot.svg'}
          alt="Service Tile"
        />
      );
    case 4: // Gateways
      return (
        <img
          src={process.env.PUBLIC_URL + '/assets/service-icons/gateways.svg'}
          alt="Service Tile"
        />
      );
    default:
      return <></>;
  }
}

export default ServiceTile;
