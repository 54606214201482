import {
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
  CircularProgress
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IComponent,
  IComponentGroup,
  IImage,
  IMarkdownTemplate,
  IReleaseNote
} from '../../../interfaces';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import StatusSelect, { IconType } from './FormComponents/StatusSelect';
import { componentSelectTitle } from '../../../utils';
import ExpandableTextbox from './FormComponents/ExpandableTextbox';
import FileUpload from 'react-material-file-upload';
import LoadingEditButton from './FormComponents/LoadingEditButton';
import useValidation from '../../../hooks/useValidation';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IReleaseNoteForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  releaseNote?: IReleaseNote;
  markdownTemplate?: IMarkdownTemplate;
  fetchReleaseNotes: any;
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result?.toString() || '');
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsDataURL(file);
  });
}

const ReleaseNoteForm = ({
  setModalOpen,
  releaseNote, //if exists: component is updated
  markdownTemplate, //if exists: component is updated
  fetchReleaseNotes,
  components,
  componentGroups
}: IReleaseNoteForm) => {
  const [name, setName] = useState<string>(releaseNote ? releaseNote.name : '');
  const [message, setMessage] = useState<string>(
    releaseNote ? releaseNote.message : ''
  );
  const [type, setType] = useState<number>(releaseNote ? releaseNote.type : 1);
  const [component_ids, setComponent_ids] = useState<string[]>(
    releaseNote ? releaseNote.components?.map((c) => c.id) : []
  );
  const [group_ids, setGroup_ids] = useState<string[]>(
    releaseNote ? releaseNote.groups?.map((c) => c.id) : []
  );
  const [versionTag, setVersionTag] = useState<string>(
    releaseNote ? releaseNote.version_tag : ''
  );
  const [images, setImages] = useState<Array<IImage>>(
    releaseNote
      ? releaseNote.images.map((i) => {
          return {
            ...i,
            base64encoding: ''
          };
        })
      : []
  );
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchReleaseNotes();
  };

  useEffect(() => {
    const loadImage = async (id: string) => {
      const image = await fetch(`/api/v1/images/${id}`);
      return (await image.json()) as IImage;
    };

    for (const image of images) {
      loadImage(image.id)
        .then((data) => {
          const newImages = [...images];
          const idx = newImages.findIndex((i) => i.id === image.id);
          newImages[idx].base64encoding = data.base64encoding;
          setImages(newImages);
        })
        .catch((error) => {
          console.error(`Could not load image ${image.id}`);
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, []);

  const validateMessage = (message: string | null) => {
    return !!message;
  };
  const [messageValid] = useValidation(validateMessage, message);
  const [nameValid, showNameValid, updateNameDiplay] = useValidation(
    validateMessage,
    name
  );

  const handleComponentSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setComponent_ids(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGroupSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setGroup_ids(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAddReleaseNote = async () => {
    if (messageValid && nameValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/release_notes/', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            name,
            type,
            message,
            version_tag: versionTag,
            component_ids,
            group_ids,
            images
          })
        });
        setLoadingEdit(false);

        handleResponse(response, 'release note', 'add', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleEditReleaseNote = async () => {
    if (!releaseNote?.id) {
      setSnackMessage('Error: release note id not given');
      setSnackSeverity('error');
      setSnackOpen(true);
      return;
    }

    if (messageValid && nameValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch(
          '/api/v1/release_notes/' + releaseNote.id,
          {
            method: 'PUT',
            headers: AuthenticationService.instance.headers,
            body: JSON.stringify({
              name,
              type,
              message,
              version_tag: versionTag,
              component_ids,
              group_ids,
              images
            })
          }
        );
        setLoadingEdit(false);

        handleResponse(response, 'release note', 'edit', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleDeleteImage = (index: number) => {
    setImages([...images.slice(0, index), ...images.slice(index + 1)]);
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          {releaseNote?.id ? 'Edit' : 'Add new'} Release Note
        </Typography>
      </div>
      <div>
        <TextFieldStyled
          {...(showNameValid
            ? { error: false }
            : { error: true, helperText: 'This field is required' })}
          onBlur={() => updateNameDiplay()}
          label="Name*"
          id="name"
          size="small"
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <TextFieldStyled
          label="Version Tag"
          id="name"
          size="small"
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          value={versionTag}
          onChange={(e) => setVersionTag(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <ExpandableTextbox
          defaultMessage={
            releaseNote?.message ??
            (markdownTemplate ? markdownTemplate.text ?? markdownTemplate : '')
          }
          message={message ?? ''}
          setMessage={setMessage}
        />
        <StatusSelect
          setStatus={setType}
          status={type}
          iconType={IconType.ReleaseNoteType}
        />
        <FormControl
          sx={{ marginTop: '20px', color: '#b7b7b7' }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="groupsSelectLabel"
            sx={{
              color: '#b7b7b7',
              borderColor: '#b7b7b7'
            }}
          >
            Select groups
          </InputLabel>
          <Select
            labelId="groupsSelectLabel"
            id="groupsSelect"
            multiple
            value={group_ids}
            onChange={(event) => handleGroupSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value) => (
                  <Chip
                    label={
                      componentGroups.filter((group) => group.id === value)[0]
                        .name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {componentGroups.map((g) => (
              <MenuItem key={g.id} value={g.id}>
                <Checkbox checked={group_ids.indexOf(g.id) > -1} />
                <ListItemText primary={g.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            marginTop: '20px',
            color: '#b7b7b7',
            svg: { color: '#b7b7b7' }
          }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="componentsSelectLabel"
            sx={{
              color: '#b7b7b7',
              borderColor: '#b7b7b7'
            }}
          >
            Select components
          </InputLabel>
          <Select
            labelId="componentsSelectLabel"
            id="componentsSelect"
            multiple
            value={component_ids}
            onChange={(event) => handleComponentSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value) => (
                  <Chip
                    label={
                      components.filter(
                        (component: any) => component.id === value
                      )[0].name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {components.map((c: IComponent) => (
              <MenuItem key={c.id} value={c.id}>
                <Checkbox checked={component_ids.indexOf(c.id) > -1} />
                <ListItemText
                  primary={componentSelectTitle(c, componentGroups)}
                />
              </MenuItem>
            ))}
          </Select>
          <h2>Images</h2>
          {images?.map((image, idx) => {
            return (
              <div key={image.id}>
                {image.base64encoding ? (
                  <CardImage
                    alt="Release Note"
                    src={`${image.base64encoding}`}
                  ></CardImage>
                ) : (
                  <CircularProgress
                    sx={{
                      margin: 'auto',
                      display: 'block',
                      color: '#ff9900 !important',
                      scale: 0.1
                    }}
                  />
                )}
                <div>
                  <Button
                    variant="contained"
                    onClick={() => handleDeleteImage(idx)}
                  >
                    Delete Image & Message
                  </Button>
                </div>
                <ExpandableTextbox
                  defaultMessage={image.message ?? ''}
                  message={image.message ?? ''}
                  setMessage={(message) => {
                    const newImages = images;
                    newImages[idx].message = message;
                    setImages(newImages);
                  }}
                  ignoreValidation={true}
                />
              </div>
            );
          })}
          <h2>
            <FileUpload
              value={[]}
              onChange={async (files) => {
                setImages(
                  images.concat({
                    id: '',
                    base64encoding: await getBase64(files[0]),
                    message: ''
                  })
                );
              }}
            ></FileUpload>
          </h2>
          <h4>
            The maximum total file size of images for each release note is
            capped to 50mb.
          </h4>
        </FormControl>
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingEditButton
            onClick={() =>
              releaseNote?.id ? handleEditReleaseNote() : handleAddReleaseNote()
            }
            loadingEdit={loadingEdit}
            text={(releaseNote?.id ? 'Edit' : 'Add') + ' Release Note'}
            disabled={!(nameValid && messageValid)}
          ></LoadingEditButton>
        </div>
      </div>
    </div>
  );
};

export default ReleaseNoteForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const CardImage = styled.img`
  margin: 20px 0;
  max-width: 500px;
`;

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
