import { useState } from 'react';
import { Divider, Tab, Grid } from '@mui/material';
import MatTabs from '@mui/material/Tabs';
import styled from 'styled-components';
import EditModal from '../Admin/EditModal';
import SubscriptionForm from '../Subscription/SubscriptionForm';
import SubscribeButton from '../ComponentView/SubscribeButton';

interface ITabs {
  tabId: number;
  handleChange: any;
}

const StyledTab = styled(Tab)`
  text-transform: none !important;
`;

// TODO Divider throws warnings

const Tabs = ({ tabId, handleChange }: ITabs) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={
          <SubscriptionForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
        }
      />
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <MatTabs
            value={tabId}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="primary"
            sx={{
              textAlign: 'left',
              alignItems: 'left'
            }}
          >
            <StyledTab label="Services" />
            <Divider orientation="vertical" variant="middle" flexItem></Divider>
            <StyledTab label="Release Notes" />
          </MatTabs>
        </Grid>
        <Grid item>
          <SubscribeButton
            buttonText="Manage Subscriptions"
            handleClick={() => setModalOpen(true)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Tabs;
