import { Divider, Grid } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

interface IPrivacyNoticeHeader {
  text: string;
  children?: any;
}

const TitleStyled = styled.h1`
  padding: 10px 20px;
  cursor: pointer;
`;

const HRule = styled(Divider)``;

const Container = styled.div`
  margin: 0 20px;
  &:hover {
    background-color: #452f1e !important;
  }
  &:hover ${HRule} {
    background-color: #fe6e01 !important;
  }
`;

const PrivacyNoticeHeader: React.FC<IPrivacyNoticeHeader> = ({
  text,
  children
}) => {
  const [expanded, setExpanded] = useState<boolean>();

  return (
    <Container>
      <HRule color="white"></HRule>
      <Grid container>
        <Grid item xs={11.5}>
          <TitleStyled
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {text}
          </TitleStyled>
        </Grid>
        <Grid item alignSelf={'center'} xs={0.5}>
          <TitleStyled
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? '—' : '+'}
          </TitleStyled>
        </Grid>
      </Grid>
      {expanded ? children : <></>}
      <HRule color="white"></HRule>
    </Container>
  );
};

export default PrivacyNoticeHeader;
