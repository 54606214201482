import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { IComponentGroup } from '../../../interfaces';

interface IListItems {
  groups: Array<IComponentGroup>;
  filterBy: Array<string>;
  handleCheckBoxClick: Function;
}

const ListItems = ({ groups, filterBy, handleCheckBoxClick }: IListItems) => {
  return (
    <>
      {groups
        ? groups.map((group) =>
            group.subgroups
              ? group.subgroups
                  .filter((sg) => sg.filterableForReleaseNotes)
                  .map((sg) => (
                    <ListItem sx={{ height: 40, pl: 0, pr: 1 }} key={sg.id}>
                      <Checkbox
                        checked={filterBy.includes(sg.id)}
                        onChange={() => handleCheckBoxClick(sg.id)}
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: '#7295C2 !important'
                          },
                          color: 'white !important',
                          opacity: '70%'
                        }}
                      />
                      <ListItemText primary={sg.name || 'null'} />
                    </ListItem>
                  ))
              : []
          )
        : []}
    </>
  );
};

export default ListItems;
