import { Typography, Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import ExpandableTextbox from './FormComponents/ExpandableTextbox';
import LoadingEditButton from './FormComponents/LoadingEditButton';
import useValidation from '../../../hooks/useValidation';
import { IMarkdownTemplate } from '../../../interfaces';
import SnackbarContext from '../../../contexts/SnackbarContext';
import styled from 'styled-components';

interface IMarkdownForm {
  markdownTemplate?: IMarkdownTemplate | undefined;
  fetchMarkdownTemplates: Function;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarkdownForm = ({
  setModalOpen,
  fetchMarkdownTemplates,
  markdownTemplate
}: IMarkdownForm) => {
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [text, setText] = useState<string>('');

  const validateMessage = (message: string | null) => {
    return !!message;
  };

  const [messageValid] = useValidation(validateMessage, text);
  const [nameValid, showNameValid, updateNameDiplay] = useValidation(
    validateMessage,
    name
  );

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchMarkdownTemplates();
  };

  /*
  const handleAddMarkdownTemplate = async () => {
    if (messageValid && nameValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/markdown_templates/', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            name,
            text
          })
        });
        setLoadingEdit(false);
        handleResponse(response, 'markdown template', 'add', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };
  */

  const handleEditMarkdownTemplate = async () => {
    if (!markdownTemplate?.id) {
      setSnackMessage('Error: markdown template id not given');
      setSnackSeverity('error');
      setSnackOpen(true);
      return;
    }

    if (messageValid && nameValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch(
          '/api/v1/markdown_templates/' + markdownTemplate.id,
          {
            method: 'PUT',
            headers: AuthenticationService.instance.headers,
            body: JSON.stringify({
              name,
              text
            })
          }
        );
        setLoadingEdit(false);
        handleResponse(response, 'markdown template', 'edit', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Set as new Markdown Template
        </Typography>
      </div>
      <div>
        <TextFieldStyled
          {...(showNameValid
            ? { error: false }
            : { error: true, helperText: 'This field is required' })}
          onBlur={() => updateNameDiplay()}
          label="Name*"
          id="name"
          size="small"
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <ExpandableTextbox
          defaultMessage={
            markdownTemplate ? markdownTemplate.text ?? markdownTemplate : ''
          }
          message={text ?? ''}
          setMessage={setText}
        />
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingEditButton
            onClick={() => {
              handleEditMarkdownTemplate();
            }}
            loadingEdit={loadingEdit}
            text={
              (markdownTemplate?.id ? 'Update' : 'Add') + ' Markdown Template'
            }
            disabled={!(nameValid && messageValid)}
          ></LoadingEditButton>
        </div>
      </div>
    </div>
  );
};

export default MarkdownForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
