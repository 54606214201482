import { Box, Chip, Grid, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { IComponentGroup } from '../../interfaces';
import CustomListItemButton from './CustomListItemButton/CustomListItemButton';

interface IFilterBar {
  filterBy: Array<string>;
  setFilterBy: any;
  components: Array<IComponentGroup>;
  groups: Array<IComponentGroup>;
  onReleaseNotePage: boolean;
}

const Container = styled.div`
  height: 48px;
  background: #414141;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  padding: 5px 40px;
`;

const MONEO_CLOUD = 'moneo|cloud';

const FilterBar: React.FC<IFilterBar> = ({
  groups,
  components,
  filterBy,
  setFilterBy,
  onReleaseNotePage
}: IFilterBar) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  function IdToNames(arr: Array<string>): Array<string> {
    const mapped = arr.map((filterById) => {
      const comp = groups.find((g) => g.id === filterById);
      return comp?.name;
    });
    return mapped.filter((name) => name) as Array<string>;
  }

  useEffect(() => {
    const searchParamsComponentFilter = searchParams.get('componentFilter');

    if (searchParamsComponentFilter) {
      const filterByArray = searchParamsComponentFilter
        .split(',')
        .map((filterByName) => {
          const comp = groups.find((g) => g.name === filterByName);
          if (!onReleaseNotePage && comp?.release_notes_only) return undefined;
          else return comp?.id;
        })
        .filter((id) => id);
      setFilterBy(filterByArray.filter((id) => id));
    } else {
      setFilterBy([]);
    }
  }, [searchParams, setFilterBy, groups, onReleaseNotePage]);

  function selectFilterBy(id: string) {
    const index = filterBy.findIndex((value) => value === id);
    const isMoneoCloud = groups.find(
      (g) => g.name === MONEO_CLOUD && g.id === id
    );
    if (index >= 0) {
      if (isMoneoCloud) {
        let filterByArray: string[] = [];
        IdToNames(filterBy).forEach((fb) => {
          groups.forEach((g) => {
            if (fb === g.name && fb !== MONEO_CLOUD && g.supergroup === null) {
              filterByArray.push(fb);
            }
          });
        });
        if (filterByArray?.length === 0) setSearchParams({});
        else setSearchParams({ componentFilter: filterByArray.join(',') });
      } else {
        // set array to everything except the index => remove from array
        let filterByArray = IdToNames([
          ...filterBy.slice(0, index),
          ...filterBy.slice(index + 1)
        ]);
        if (filterByArray?.length === 0) setSearchParams({});
        else setSearchParams({ componentFilter: filterByArray.join(',') });
      }
    } else {
      const comp = groups.find((g) => g.id === id);
      if (
        (!onReleaseNotePage && comp) ||
        (comp && comp.name && !isMoneoCloud)
      ) {
        const filterByArray = IdToNames(filterBy).concat([comp.name]);
        if (filterByArray?.length === 0) {
          setSearchParams({});
        } else {
          setSearchParams({ componentFilter: filterByArray.join(',') });
        }
      } else {
        console.error('Component', comp, 'has no name');
      }
    }
  }

  function getCheckedFilterCount() {
    let count = 0;
    filterBy.forEach((filterId) => {
      groups.forEach((group) => {
        let comp = group.subgroups.find(
          (g) => g.id === filterId && g.filterableForReleaseNotes === true
        );
        if (comp !== undefined) {
          count++;
        }
      });
    });
    return count;
  }

  const checkedFilterCount = getCheckedFilterCount();

  function uncheckAllBoxes() {
    let filterByArray: string[] = [];
    IdToNames(filterBy).forEach((fb) => {
      groups.forEach((g) => {
        if (fb === g.name && g.supergroup === null) {
          filterByArray.push(fb);
        }
      });
    });
    if (filterByArray?.length === 0) setSearchParams({});
    else setSearchParams({ componentFilter: filterByArray.join(',') });
  }

  function checkAllBoxes() {
    const filterByArray = IdToNames(filterBy);
    groups?.forEach((group) =>
      group.subgroups?.forEach((sg) => {
        if (sg.filterableForReleaseNotes === true) {
          filterByArray.push(sg.name);
        }
      })
    );
    if (filterByArray?.length === 0) {
      setSearchParams({});
    } else {
      setSearchParams({ componentFilter: filterByArray.join(',') });
    }
  }

  function checkOrUncheckAllBoxes() {
    if (checkedFilterCount > 0) {
      uncheckAllBoxes();
    } else {
      checkAllBoxes();
    }
  }

  return (
    <Container>
      <Grid container alignContent="center">
        <Grid item xs={0} lg={1}></Grid>
        <Grid container item xs alignContent="center" spacing={1}>
          {components
            ? components.map((c) => {
                const chip = (
                  <Box>
                    <Chip
                      clickable
                      label={
                        c.name === MONEO_CLOUD && onReleaseNotePage ? (
                          <Box display={'flex'} alignItems={'center'}>
                            {c.name} {'('}
                            {checkedFilterCount}
                            {')'}
                            <CustomListItemButton
                              groups={groups}
                              filterBy={filterBy}
                              selectFilterBy={selectFilterBy}
                            />
                          </Box>
                        ) : (
                          c.name
                        )
                      }
                      onClick={(e) => {
                        selectFilterBy(c.id);
                        if (c.name === MONEO_CLOUD && onReleaseNotePage) {
                          checkOrUncheckAllBoxes();
                        }
                      }}
                      disabled={c.release_notes_only && !onReleaseNotePage}
                      sx={{
                        border: '#7295C2',
                        marginRight: '20px',
                        background:
                          ((filterBy.includes(c.id) ||
                            checkedFilterCount > 0) &&
                            c.name === MONEO_CLOUD &&
                            onReleaseNotePage) ||
                          filterBy.includes(c.id)
                            ? '#7295C280'
                            : {},
                        color: '#ffffff',
                        borderStyle: 'solid',
                        borderWidth: '1px'
                      }}
                    />
                  </Box>
                );

                return c.release_notes_only && !onReleaseNotePage ? (
                  <Grid item key={c.id}>
                    <Tooltip
                      title="You can only filter by this group in the release notes tab."
                      placement="bottom"
                    >
                      <div>{chip}</div>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item key={c.id}>
                    {chip}
                  </Grid>
                );
              })
            : []}
        </Grid>
        <Grid item xs={0} lg={1}></Grid>
      </Grid>
    </Container>
  );
};

export default FilterBar;
