import {
  TextField,
  Typography,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Chip
} from '@mui/material';
import React, { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import moment from 'moment';
import StatusSelect, { IconType } from './FormComponents/StatusSelect';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import {
  IComponent,
  IComponentGroup,
  IScheduledMaintenance
} from '../../../interfaces';
import { componentSelectTitle } from '../../../utils';
import { v4 as uuidv4 } from 'uuid';
import ExpandableTextbox from './FormComponents/ExpandableTextbox';
import LoadingEditButton from './FormComponents/LoadingEditButton';
import useValidation from '../../../hooks/useValidation';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IScheduledMaintenanceForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchScheduledMaintenances: any;
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
  scheduledMaintenance?: IScheduledMaintenance;
}

const ScheduledMaintenanceForm = ({
  setModalOpen,
  fetchScheduledMaintenances,
  components,
  componentGroups,
  scheduledMaintenance
}: IScheduledMaintenanceForm) => {
  const createComponentIds = (components: Array<IComponent>) => {
    let component_ids: string[] = [];
    components.forEach((e) => {
      component_ids.push(e.id);
    });
    return component_ids;
  };
  const createGroupIds = (groups: Array<IComponentGroup>) => {
    let group_ids: string[] = [];
    groups.forEach((e) => {
      group_ids.push(e.id);
    });
    return group_ids;
  };

  const [name, setName] = useState<string | null>(
    scheduledMaintenance?.name ?? null
  );
  const [status, setStatus] = useState<number>(
    scheduledMaintenance?.status ?? 0
  );
  const [message, setMessage] = useState<string | null>(
    scheduledMaintenance?.message ?? null
  );
  const [scheduledFrom, setScheduledFrom] = useState<Date | null>(
    scheduledMaintenance?.scheduledFrom
      ? new Date(scheduledMaintenance.scheduledFrom)
      : null
  );
  const [scheduledFromTime, setScheduledFromTime] = useState<Date | null>(
    scheduledMaintenance?.scheduledFrom
      ? moment({
          h: moment(scheduledMaintenance.scheduledFrom).hour(),
          m: moment(scheduledMaintenance.scheduledFrom).minute()
        }).toDate()
      : null
  );
  const [scheduledTo, setScheduledTo] = useState<Date | null>(
    scheduledMaintenance?.scheduledTo
      ? new Date(scheduledMaintenance.scheduledTo)
      : null
  );
  const [scheduledToTime, setScheduledToTime] = useState<Date | null>(
    scheduledMaintenance?.scheduledTo
      ? moment({
          h: moment(scheduledMaintenance.scheduledTo).hour(),
          m: moment(scheduledMaintenance.scheduledTo).minute()
        }).toDate()
      : null
  );

  const scheduledFromFinal = useMemo(
    () =>
      moment({
        y: moment(scheduledFrom).year(),
        M: moment(scheduledFrom).month(),
        d: moment(scheduledFrom).date(),
        h: moment(scheduledFromTime).hour(),
        m: moment(scheduledFromTime).minute()
      }),
    [scheduledFrom, scheduledFromTime]
  );

  const scheduledToFinal = useMemo(
    () =>
      moment({
        y: moment(scheduledTo).year(),
        M: moment(scheduledTo).month(),
        d: moment(scheduledTo).date(),
        h: moment(scheduledToTime).hour(),
        m: moment(scheduledToTime).minute()
      }),
    [scheduledTo, scheduledToTime]
  );

  const [component_ids, setComponent_ids] = useState<string[]>(
    scheduledMaintenance?.components
      ? createComponentIds(scheduledMaintenance.components)
      : []
  );
  const [group_ids, setGroup_ids] = useState<string[]>(
    scheduledMaintenance?.groups
      ? createGroupIds(scheduledMaintenance.groups)
      : []
  );
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchScheduledMaintenances();
  };

  //Validation

  const validateMessage = (message: string | null) => {
    return !!message;
  };

  const validateRange = () => {
    if (
      !scheduledFrom ||
      !scheduledTo ||
      !scheduledFromTime ||
      !scheduledToTime
    ) {
      return true;
    }

    if (scheduledFromFinal > scheduledToFinal) {
      return false;
    }
    return true;
  };

  const [nameValid, showNameValid, updateNameDisplay] = useValidation(
    validateMessage,
    name
  );
  const [messageValid] = useValidation(validateMessage, message);
  const [fromValid] = useValidation(validateMessage, scheduledFrom);
  const [fromTimeValid] = useValidation(validateMessage, scheduledFromTime);
  const [toValid] = useValidation(validateMessage, scheduledTo);
  const [toTimeValid] = useValidation(validateMessage, scheduledToTime);
  const [rangeValid] = useValidation(
    validateRange,
    scheduledFrom,
    scheduledFromTime,
    scheduledTo,
    scheduledToTime
  );

  const handleAddScheduledMaintenance = async () => {
    if (
      messageValid &&
      nameValid &&
      fromValid &&
      fromTimeValid &&
      toValid &&
      toTimeValid &&
      rangeValid
    ) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/scheduled_maintenances/', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            id: uuidv4(),
            scheduledFrom: scheduledFromFinal,
            scheduledTo: scheduledToFinal,
            manuallyCreated: true,
            name,
            visible: true,
            status,
            message,
            component_status: status,
            notify: true,
            created_at: new Date(),
            updated_at: null,
            component_ids,
            group_ids
          })
        });
        setLoadingEdit(false);

        handleResponse(
          response,
          'scheduled maintenance',
          'add',
          responseCallback
        );
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleEditScheduledMaintenance = async () => {
    if (
      messageValid &&
      nameValid &&
      fromValid &&
      fromTimeValid &&
      toValid &&
      toTimeValid &&
      rangeValid
    ) {
      if (!scheduledMaintenance?.id) {
        setSnackMessage('Error: scheduled maintenance id not given');
        setSnackSeverity('error');
        setSnackOpen(true);
        return;
      }

      setLoadingEdit(true);
      try {
        const response = await fetch(
          '/api/v1/scheduled_maintenances/' + scheduledMaintenance.id,
          {
            method: 'PUT',
            headers: AuthenticationService.instance.headers,
            body: JSON.stringify({
              scheduledFrom: scheduledFromFinal,
              scheduledTo: scheduledToFinal,
              name,
              status,
              message,
              component_status: status,
              updated_at: new Date(),
              component_ids,
              group_ids
            })
          }
        );
        setLoadingEdit(false);

        handleResponse(
          response,
          'scheduled maintenance',
          'edit',
          responseCallback
        );
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const handleComponentSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setComponent_ids(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGroupSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setGroup_ids(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Edit scheduled maintenance
        </Typography>
      </div>

      <div>
        <TextFieldStyled
          {...(showNameValid
            ? { error: false }
            : { error: true, helperText: 'This field is required' })}
          onBlur={() => updateNameDisplay()}
          label="Name*"
          id="name"
          size="small"
          defaultValue={scheduledMaintenance?.name ?? ''}
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          onChange={(e: any) => setName(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <ExpandableTextbox
          defaultMessage={scheduledMaintenance?.message ?? ''}
          message={message ?? ''}
          setMessage={setMessage}
        />
        <div style={styles.flex}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
              {...(scheduledTo ? { maxDate: scheduledTo } : {})}
              label="Scheduled from*"
              value={scheduledFrom}
              onChange={(newDate) => {
                setScheduledFrom(newDate);
              }}
              InputProps={{ style: { color: 'white' } }}
              renderInput={(InputProps) => (
                <TextFieldStyled
                  {...InputProps}
                  size="small"
                  style={{ marginTop: '20px' }}
                  sx={{
                    svg: { color: '#b7b7b7' }
                  }}
                />
              )}
            />
            <TimePicker
              {...(scheduledFrom &&
              scheduledTo &&
              scheduledFrom.toDateString() === scheduledTo.toDateString()
                ? {
                    maxTime: scheduledToTime
                  }
                : {})}
              label="Time*"
              value={scheduledFromTime}
              onChange={(newTime) => {
                setScheduledFromTime(newTime);
              }}
              InputProps={{ style: { color: 'white' } }}
              renderInput={(InputProps) => (
                <TextFieldStyled
                  {...InputProps}
                  size="small"
                  style={{ marginTop: '20px' }}
                  sx={{
                    svg: { color: '#b7b7b7' }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={styles.flex}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
              {...(scheduledFrom ? { minDate: scheduledFrom } : {})}
              label="Scheduled until*"
              value={scheduledTo}
              onChange={(newDate) => {
                setScheduledTo(newDate);
              }}
              InputProps={{ style: { color: 'white' } }}
              renderInput={(InputProps) => (
                <TextFieldStyled
                  {...InputProps}
                  size="small"
                  style={{ marginTop: '20px' }}
                  sx={{
                    svg: { color: '#b7b7b7' }
                  }}
                />
              )}
            />
            <TimePicker
              {...(scheduledFrom &&
              scheduledTo &&
              scheduledFrom.toDateString() === scheduledTo.toDateString()
                ? {
                    minTime: scheduledFromTime
                  }
                : {})}
              label="Time*"
              value={scheduledToTime}
              onChange={(newTime) => {
                setScheduledToTime(newTime);
              }}
              InputProps={{ style: { color: 'white' } }}
              renderInput={(InputProps) => (
                <TextFieldStyled
                  {...InputProps}
                  size="small"
                  style={{ marginTop: '20px' }}
                  sx={{
                    svg: { color: '#b7b7b7' }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <FormControl
          sx={{ marginTop: '20px', color: '#b7b7b7' }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="groupsSelectLabel"
            sx={{
              color: '#b7b7b7',
              borderColor: '#b7b7b7'
            }}
          >
            Select affected groups
          </InputLabel>
          <Select
            labelId="groupsSelectLabel"
            id="groupsSelect"
            multiple
            value={group_ids}
            onChange={(event) => handleGroupSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value) => (
                  <Chip
                    label={
                      componentGroups.filter((group) => group.id === value)[0]
                        .name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {componentGroups.map((g) => (
              <MenuItem key={g.id} value={g.id}>
                <Checkbox checked={group_ids.indexOf(g.id) > -1} />
                <ListItemText primary={g.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{ marginTop: '20px', color: '#b7b7b7' }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="componentsSelectLabel"
            sx={{
              color: '#b7b7b7',
              borderColor: '#b7b7b7'
            }}
          >
            Select affected components
          </InputLabel>
          <Select
            labelId="componentsSelectLabel"
            id="componentsSelect"
            multiple
            value={component_ids}
            onChange={(event) => handleComponentSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value) => (
                  <Chip
                    label={
                      components.filter(
                        (component: any) => component.id === value
                      )[0].name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {components.map((c: any) => (
              <MenuItem key={c.id} value={c.id}>
                <Checkbox checked={component_ids.indexOf(c.id) > -1} />
                <ListItemText
                  primary={componentSelectTitle(c, componentGroups)}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StatusSelect
          iconType={IconType.IncidentStatus}
          setStatus={setStatus}
          status={status}
        />
        <Typography variant="body2" color="#b7b7b7" sx={{ marginTop: '8px' }}>
          *required fields
        </Typography>
        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingEditButton
            onClick={() =>
              scheduledMaintenance?.id
                ? handleEditScheduledMaintenance()
                : handleAddScheduledMaintenance()
            }
            loadingEdit={loadingEdit}
            text={
              (scheduledMaintenance?.id ? 'Edit' : 'Add') +
              ' Scheduled Maintenance'
            }
            disabled={
              !(
                messageValid &&
                nameValid &&
                fromValid &&
                fromTimeValid &&
                toValid &&
                toTimeValid &&
                rangeValid
              )
            }
          ></LoadingEditButton>
        </div>
      </div>
    </div>
  );
};

export default ScheduledMaintenanceForm;

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
