import { Modal, Box, Button } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface IEditModal {
  content?: any;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditModal = ({ content, modalOpen, setModalOpen }: IEditModal) => {
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <div>
          <Box sx={style.box}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                marginTop: '5px'
              }}
            >
              <Button onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </Button>
            </div>
            {content}
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default EditModal;

const style = {
  box: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '40%',
    height: 'fit-content',
    backgroundColor: '#333333',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0
  }
};
