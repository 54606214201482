import { Card, CardContent, Typography, Button, Tooltip } from '@mui/material';
import AdminSectionHeader from '../components/Admin/AdminSectionHeader';
import { useContext, useState } from 'react';
import EditModal from '../components/Admin/EditModal';
import styled from 'styled-components';
import ComponentForm from '../components/Admin/Forms/ComponentForm';
import { iconFromComponentStatus } from '../components/StatusIcon';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import { IComponent, IComponentGroup } from '../interfaces';
import SnackbarContext from '../contexts/SnackbarContext';

interface IAdminComponentPage {
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
  fetchComponents: any;
}
const AdminComponentPage = ({
  components,
  fetchComponents,
  componentGroups
}: IAdminComponentPage) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<any>(null);
  const { handleResponse } = useContext(SnackbarContext);

  const handleAddComponent = () => {
    setFormContent(
      <ComponentForm
        setModalOpen={setModalOpen}
        fetchComponents={fetchComponents}
        componentGroups={componentGroups}
      />
    );
    setModalOpen(true);
  };

  const handleEditComponent = (component: any) => {
    setFormContent(
      <ComponentForm
        setModalOpen={setModalOpen}
        fetchComponents={fetchComponents}
        component={component}
        componentGroups={componentGroups}
      />
    );
    setModalOpen(true);
  };

  function discourageFromDeleting(component: IComponent): boolean {
    if (
      !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
        component.id
      )
    )
      return true;
    return false;
  }

  const handleDeleteComponentCallback = () => {
    setModalOpen(false);
    fetchComponents();
  };

  const handleDeleteComponent = async (component_id: string) => {
    try {
      const response = await fetch('/api/v1/components/' + component_id, {
        method: 'DELETE',
        headers: AuthenticationService.instance.headers
      });

      handleResponse(
        response,
        'component',
        'delete',
        handleDeleteComponentCallback
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={formContent}
      />
      <AdminSectionHeader
        title="Components"
        buttonText="Add new Component"
        handleClick={() => handleAddComponent()}
      ></AdminSectionHeader>
      <div>
        {components.map((i) => {
          return (
            <div key={i.id} style={{ display: 'flex', width: '100%' }}>
              <StyledIncidentIconContainer>
                {iconFromComponentStatus(i.status)}
              </StyledIncidentIconContainer>
              <CardStyled boxshadow="true">
                <CardContentStyled padding={'1em'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h5" component="div">
                      {i.name}
                    </Typography>
                    <div>
                      <Button
                        variant="outlined"
                        style={{ marginRight: '10px' }}
                        onClick={() => handleEditComponent(i)}
                      >
                        Edit
                      </Button>
                      {discourageFromDeleting(i) ? (
                        <Tooltip
                          title="The component has additional functionality or is automatically loaded from the statuspages. It is therefore discouraged from deletion."
                          placement="left-start"
                        >
                          <span>
                            <Button
                              variant="contained"
                              disabled
                              onClick={() => handleDeleteComponent(i.id)}
                            >
                              Delete
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => handleDeleteComponent(i.id)}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                  {i.groups?.length ? (
                    <div style={{ marginTop: '5px' }}>
                      Grouped under:{' '}
                      {i.groups.map((el: any) => el.name).join(', ')}
                    </div>
                  ) : (
                    <></>
                  )}
                  {i.link ? (
                    <div style={{ marginTop: '5px' }}>Source: {i.link}</div>
                  ) : (
                    <></>
                  )}
                </CardContentStyled>
              </CardStyled>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminComponentPage;

const CardStyled = styled(Card)<any>`
  box-shadow: ${({ boxshadow }) =>
    boxshadow
      ? '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important'
      : 'none !important'};
  border-radius: 0 !important;
  margin-bottom: 20px;
  width: 100%;
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #414141;
  ${({ padding }) => ('padding: ' + padding ? padding : '0px !important')};
  color: #ffffff;
`;

const StyledIncidentIconContainer = styled.div`
  background: #555555;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-right: 20px;
`;
