import { Button, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState, useCallback, useContext } from 'react';
import AdminSectionHeader from '../components/Admin/AdminSectionHeader';
import { IUser } from '../interfaces';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import UserForm from '../components/Admin/Forms/UserForm';
import EditModal from '../components/Admin/EditModal';
import AreYouSureButton from '../components/AreYouSureButton';
import SnackbarContext from '../contexts/SnackbarContext';

const AdminUsersPage = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<any>(null);
  const { handleResponse, setSnackOpen, setSnackMessage, setSnackSeverity } =
    useContext(SnackbarContext);
  const [users, setUsers] = useState<Array<IUser>>([]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch('/api/v1/users/', {
        headers: AuthenticationService.instance.headers
      });
      setUsers((await response.json()).data);
    } catch (error) {
      console.error(error);
      setSnackOpen(true);
      setSnackMessage(error as string);
      setSnackSeverity('error');
    }
  }, [setSnackOpen, setSnackMessage, setSnackSeverity]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleAddUser = () => {
    setFormContent(
      <UserForm setModalOpen={setModalOpen} fetchUsers={fetchUsers} />
    );
    setModalOpen(true);
  };

  const handleEditUser = (user: IUser) => {
    setFormContent(
      <UserForm
        setModalOpen={setModalOpen}
        fetchUsers={fetchUsers}
        user={user}
      />
    );
    setModalOpen(true);
  };

  const handleUserDeleteCallback = () => {
    setModalOpen(false);
    fetchUsers();
  };

  const handleDeleteUser = async (user: IUser) => {
    try {
      const response = await fetch('/api/v1/users/' + user.id, {
        method: 'DELETE',
        headers: AuthenticationService.instance.headers
      });

      handleResponse(response, 'user', 'delete', handleUserDeleteCallback);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={formContent}
      />
      <AdminSectionHeader
        title="Admin Users"
        handleClick={() => handleAddUser()}
        buttonText="Add new user"
      ></AdminSectionHeader>
      {users.map((user) => {
        return (
          <Paper
            key={user.id}
            sx={{
              backgroundColor: '#414141',
              marginBottom: '20px',
              color: 'white',
              width: '100%',
              maxWidth: '100%',
              padding: '20px 0'
            }}
          >
            <Grid container>
              <Grid item xs>
                <Typography variant="h6" sx={{ marginLeft: '20px' }}>
                  {user.email}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ marginRight: '10px' }}
                  onClick={() => handleEditUser(user)}
                >
                  Edit
                </Button>
                <AreYouSureButton
                  variant="contained"
                  sx={{ marginRight: '20px' }}
                  onClick={() => handleDeleteUser(user)}
                >
                  Delete
                </AreYouSureButton>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </div>
  );
};

export default AdminUsersPage;
