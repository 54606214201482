import { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import useValidation from '../../../../hooks/useValidation';

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});

interface IExpandableTextbox {
  defaultMessage: string;
  message: string;
  setMessage: (arg0: string) => void;
  ignoreValidation?: boolean;
}

const ExpandableTextbox = ({
  defaultMessage,
  message,
  setMessage,
  ignoreValidation
}: IExpandableTextbox) => {
  const [focusedTextfield, setTextfieldFocused] = useState<boolean>(false);
  const onTextfieldFocus = () => setTextfieldFocused(true);
  const onTextfieldBlur = () => setTextfieldFocused(false);

  const validateMessage = (message: string | null) => {
    return !!message;
  };
  // eslint-disable-next-line
  const [_, showMessageValid, updateMessageDiplay] = useValidation(
    validateMessage,
    message
  );

  return (
    <TextFieldStyled
      {...(showMessageValid
        ? { error: false }
        : { error: true, helperText: 'This field is required' })}
      label="Message*"
      id="message"
      size="small"
      multiline
      rows={focusedTextfield ? 20 : 4}
      defaultValue={defaultMessage ?? ''}
      fullWidth
      InputProps={{ style: { color: 'white' } }}
      onChange={(e) => setMessage(e.currentTarget.value)}
      style={{ marginTop: '20px' }}
      onBlur={() => {
        if (!ignoreValidation) updateMessageDiplay();
        onTextfieldBlur();
      }}
      onFocus={() => onTextfieldFocus()}
    />
  );
};

export default ExpandableTextbox;
