import jwt_decode from 'jwt-decode';
import { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { IComponentGroup } from '../interfaces';
import SectionHeader from '../components/SectionHeader';
import StickyFooter from '../components/StickyFooter';
import { renderComponents } from '../components/ComponentView';
import StatuspageAppBar from '../components/StatuspageAppBar';
import SnackbarContext from '../contexts/SnackbarContext';

interface ITokenPayload {
  iat: number;
  exp: number;
  subscriberId: string;
  email: string;
}

const ManageSubscriptionPage = () => {
  const [componentGroups, setComponentGroups] = useState<IComponentGroup[]>([]);
  const [checkedGroups, setCheckedGroups] = useState<
    Array<{
      id: string;
      sub_to_services: boolean;
      sub_to_release_notes: boolean;
    }>
  >([]);
  const { setSnackOpen, setSnackMessage, setSnackSeverity } =
    useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const handleLoadPage = async () => {
      const token = searchParams.get('token');

      if (!token) {
        console.error('No token provided.');
        setSnackMessage('No token provided.');
        setSnackSeverity('error');
        setSnackOpen(true);
        return;
      }

      let tokenPayload = jwt_decode<ITokenPayload>(token);
      if (Date.now() > tokenPayload.exp * 1000) {
        console.log('now', Date.now(), 'exp', tokenPayload.exp);
        console.error(
          'Your token has expired. Request a new one from the main page.'
        );
        setSnackMessage(
          'Your token has expired. Request a new one from the main page.'
        );
        setSnackSeverity('error');
        setSnackOpen(true);
        return;
      }

      setToken(token);

      try {
        const response = await fetch('/api/v1/subscribers/components/', {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        });
        setCheckedGroups(
          (await response.json()).data.groups.map((g: any) => {
            return {
              id: g.id,
              sub_to_services: g.sub_to_services,
              sub_to_release_notes: g.sub_to_release_notes
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };

    handleLoadPage();
  }, [searchParams, setSnackMessage, setSnackOpen, setSnackSeverity]);

  useEffect(() => {
    async function fetchComponents() {
      try {
        const response = await fetch('/api/v1/components/groups/');
        const groups = (await response.json()).data;
        if (groups) {
          setComponentGroups(
            groups.filter((g: IComponentGroup) => !g.release_notes_only)
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchComponents();
  }, []);

  const handleUpdateSubscription = async () => {
    // Get user id from JWT token
    let tokenPayload = jwt_decode<ITokenPayload>(token);
    if (tokenPayload.subscriberId) {
      try {
        const response = await fetch('/api/v1/subscribers/components', {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            verify: true,
            component_ids: [],
            groups: checkedGroups
          })
        });

        if (!response.ok) {
          console.error('Error updating subscription');
          console.error('Response', response);
          setSnackMessage('Error updating subscription');
          setSnackSeverity('error');
          setSnackOpen(true);
        } else {
          setSnackMessage('Your subscription has been updated successfully');
          setSnackSeverity('success');
          setSnackOpen(true);
          setTimeout(() => {
            window.location.assign('/');
          }, 2000);
        }
      } catch (error) {}
    } else {
      console.error('Your access token has expired');
      setSnackMessage('Your access token has expired');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  const selectGroup = (
    id: string,
    sub_to_services?: boolean,
    sub_to_release_notes?: boolean
  ) => {
    let currentGroupsSelection = [...checkedGroups];

    const selectedGroup = currentGroupsSelection.find((g) => g.id === id);
    if (!selectedGroup) {
      currentGroupsSelection.push({
        id,
        sub_to_services: sub_to_services ?? false,
        sub_to_release_notes: sub_to_release_notes ?? false
      });
    } else {
      if (sub_to_release_notes !== undefined)
        selectedGroup.sub_to_release_notes = sub_to_release_notes;
      if (sub_to_services !== undefined)
        selectedGroup.sub_to_services = sub_to_services;
    }

    currentGroupsSelection = currentGroupsSelection.filter(
      (group) => group.sub_to_release_notes || group.sub_to_services
    );
    setCheckedGroups(currentGroupsSelection);
  };

  const subbedToServices = (group_id: string) => {
    const group = checkedGroups.find((g) => group_id === g.id);
    if (group) return group.sub_to_services ?? false;
    return false;
  };

  const subbedToReleaseNotes = (group_id: string) => {
    const group = checkedGroups.find((g) => group_id === g.id);
    if (group) return group.sub_to_release_notes ?? false;
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <StatuspageAppBar onAdminPage={false} />
      <Grid container sx={{ marginBottom: '250px' }}>
        <Grid item xs={0} lg={1}></Grid>
        <Grid item xs>
          <div style={{ marginTop: '20px' }}>
            <MainContent>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs container direction="column">
                  <Grid item xs>
                    <Typography variant="h5">
                      Subscribe for services and release notes
                    </Typography>
                  </Grid>

                  <Grid item xs>
                    <Typography variant="h6">
                      Please select your subscription for services,
                      notifications, and latest release notes.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: '#ffffff',
                      backgroundColor: '#7295C266',
                      border: 'solid 1px',
                      borderColor: '#7295C2',
                      '&:hover': {
                        backgroundColor: '#7295C266',
                        borderColor: '#7295C2'
                      }
                    }}
                    onClick={() => handleUpdateSubscription()}
                  >
                    Update Subscription
                  </Button>
                </Grid>
              </Grid>

              <SectionHeader title="Services" />

              {componentGroups && (
                <Grid container spacing={2}>
                  {renderComponents(
                    componentGroups,
                    undefined,
                    0,
                    true,
                    (id: string, checked: boolean) => {
                      selectGroup(id, checked);
                    },
                    subbedToServices
                  )}
                </Grid>
              )}

              <SectionHeader title="Release Notes" />

              {componentGroups && (
                <Grid container spacing={2}>
                  {renderComponents(
                    componentGroups,
                    undefined,
                    0,
                    false,
                    (id: string, checked: boolean) => {
                      selectGroup(id, undefined, checked);
                    },
                    subbedToReleaseNotes
                  )}
                </Grid>
              )}
              <div style={{ margin: '0 0 -50px 0', height: '50px' }}></div>
            </MainContent>
          </div>
        </Grid>

        <Grid item xs={0} lg={1}></Grid>
      </Grid>
      <StickyFooter></StickyFooter>
    </ThemeProvider>
  );
};

export default ManageSubscriptionPage;

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9900'
    },
    secondary: {
      main: '#333333'
    },
    background: {
      default: '#222222'
    }
  }
});

const MainContent = styled.div`
  color: #ffffff;
  padding: 0px 30px;
  overflow: hidden;
`;
