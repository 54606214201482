import { useState, useMemo, useCallback } from 'react';

const useValidation = (
  validateFn: (...params: any[]) => boolean,
  ...params: any[]
) => {
  // eslint-disable-next-line
  const valid = useMemo(() => validateFn(...params), [...params, validateFn]);
  const [showValid, setShowValid] = useState<boolean>(true);

  const updateDisplay = useCallback(() => {
    setShowValid(valid);
  }, [valid]);

  return [valid, showValid, updateDisplay] as const;
};

export default useValidation;
