import '../App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import Tabs from '../components/Tabs';
import { useEffect, useMemo, useState } from 'react';
import IncidentPage from './IncidentPage';
import ReleaseNotePage from './ReleaseNotePage';
import { Grid } from '@mui/material';
import FilterBar from '../components/FilterBar';
import StickyFooter from '../components/StickyFooter';
import { IComponentGroup, IIncident, IReleaseNote } from '../interfaces';
import StatuspageAppBar from '../components/StatuspageAppBar';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9900'
    },
    secondary: {
      main: '#333333'
    },
    background: {
      default: '#222222'
    }
  }
});

const MainContent = styled.div`
  color: #ffffff;
  padding: 0px 30px;
  overflow: auto;
`;

// how many days to fetch in the past?
const DAYS_BEFORE = 30;
const fromDate = new Date();
fromDate.setDate(fromDate.getDate() - DAYS_BEFORE);

export const DAYS_BEFORE_RELEASE_NOTES = 180;
const initialDateReleaseNotes = new Date();
initialDateReleaseNotes.setDate(
  initialDateReleaseNotes.getDate() - DAYS_BEFORE_RELEASE_NOTES
);

// TODO Divider does not render!

function filterByIncludedComponents(
  components: Array<{ id: string }>,
  groups: Array<{ id: string }>,
  filterBy: Array<string>,
  filteredComponentIds: Array<string>,
  filteredGroupIds: Array<string>
) {
  if (!filterBy.length) return true;

  for (const g of groups) {
    if (filterBy.includes(g.id) || filteredGroupIds.includes(g.id)) return true;
  }

  for (const c of components) {
    if (filteredComponentIds.includes(c.id)) return true;
  }

  return false;
}

function HomePage() {
  const [tabId, setTabId] = useState(0);
  const [components, setComponents] = useState<Array<IComponentGroup>>([]);
  const [groups, setGroups] = useState<Array<IComponentGroup>>([]);
  const [incidents, setIncidents] = useState<Array<IIncident>>([]);
  const [releaseNotes, setReleaseNotes] = useState<Array<IReleaseNote>>([]);
  const [fromDateReleaseNotes, setFromDateReleaseNotes] = useState<Date>(
    initialDateReleaseNotes
  );
  const [filterBy, setFilterBy] = useState<Array<string>>([]);
  const [filterByRNTypes, setFilterByRNTypes] = useState<Array<number>>([]);
  const [scheduledMaintenances, setScheduledMaintenances] = useState<
    Array<IIncident>
  >([]);

  const filteredComponentIds = useMemo<Array<string>>(() => {
    function getIncludedComponents(group_ids: Array<string>): Array<string> {
      const groupsSeen: Array<string> = [];

      function GetComponentsInGroups(group_ids: Array<string>) {
        let ownComponents: Array<string> = [];
        for (const group_id of group_ids) {
          if (groupsSeen.includes(group_id)) return [];
          const group = groups.find((g) => g.id === group_id);
          if (group) {
            groupsSeen.push(group.id);
            ownComponents = ownComponents.concat(
              (group.components as Array<{ id: string }>).map((c) => c.id)
            );
            for (const subgroup of group.subgroups) {
              ownComponents = ownComponents.concat(
                GetComponentsInGroups([subgroup.id])
              );
            }
          }
        }
        return ownComponents;
      }
      return GetComponentsInGroups(group_ids);
    }

    if (!filterBy.length) {
      return [];
    }
    return getIncludedComponents(filterBy);
  }, [filterBy, groups]);

  const filteredGroupIds = useMemo<Array<string>>(() => {
    function GetGroupsInGroups(group_ids: Array<string>) {
      let allGroups: Array<string> = [];

      for (const group_id of group_ids) {
        const group = groups.find((g) => g.id === group_id);
        if (!group) continue;
        allGroups = allGroups.concat(group.subgroups.map((s) => s.id));
        const subgroupGroups = GetGroupsInGroups(
          group.subgroups.map((g) => g.id)
        );
        allGroups = allGroups.concat(subgroupGroups);
      }
      return [...new Set(allGroups)];
    }

    if (!filterBy.length) {
      return [];
    }
    return GetGroupsInGroups(filterBy);
  }, [filterBy, groups]);

  /* FETCH DATA */

  useEffect(() => {
    async function fetchGroups() {
      try {
        const response = await fetch(
          '/api/v1/components/groups/?hierarchy=false&getInvisible=true'
        );
        setGroups((await response.json()).data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchGroups();
  }, []);

  useEffect(() => {
    async function fetchComponents() {
      try {
        const response = await fetch('/api/v1/components/groups/');
        setComponents((await response.json()).data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchComponents();
  }, []);

  useEffect(() => {
    function getQueryParams() {
      const params = new URLSearchParams({ from: fromDate.toISOString() });
      return params.toString();
    }

    async function fetchIncidents() {
      try {
        const response = await fetch(`/api/v1/incidents?${getQueryParams()}`);
        let incidents = (await response.json()).data;
        if (filterBy.length) {
          incidents = incidents.filter((i: IIncident) =>
            filterByIncludedComponents(
              i.components,
              i.groups,
              filterBy,
              filteredComponentIds,
              filteredGroupIds
            )
          );
        }
        setIncidents(incidents);
      } catch (error) {
        console.error(error);
      }
    }

    fetchIncidents();
  }, [filteredComponentIds, filteredGroupIds, filterBy]);

  useEffect(() => {
    function getQueryParams() {
      const params = new URLSearchParams({ from: fromDate.toISOString() });
      return params.toString();
    }

    async function fetchScheduledMaintenances() {
      try {
        const response = await fetch(
          `/api/v1/scheduled_maintenances?${getQueryParams()}`
        );
        let schedMaint = (await response.json()).data;
        if (filterBy.length) {
          schedMaint = schedMaint.filter((i: IIncident) =>
            filterByIncludedComponents(
              i.components,
              i.groups,
              filterBy,
              filteredComponentIds,
              filteredGroupIds
            )
          );
        }
        setScheduledMaintenances(schedMaint);
      } catch (error) {
        console.error(error);
      }
    }

    fetchScheduledMaintenances();
  }, [filteredComponentIds, filteredGroupIds, filterBy]);

  useEffect(() => {
    function getQueryParams() {
      const params = new URLSearchParams({
        from: fromDateReleaseNotes.toISOString()
      });
      if (filterByRNTypes) params.append('types', filterByRNTypes.join(','));
      return params.toString();
    }

    async function fetchReleaseNotes() {
      try {
        const response = await fetch(
          `/api/v1/release_notes?${getQueryParams()}`
        );
        let rnotes = (await response.json()).data;
        if (filterBy) {
          rnotes = rnotes.filter((i: IReleaseNote) =>
            filterByIncludedComponents(
              i.components,
              i.groups,
              filterBy,
              filteredComponentIds,
              filteredGroupIds
            )
          );
        }
        setReleaseNotes(rnotes);
      } catch (error) {
        console.error(error);
      }
    }

    fetchReleaseNotes();
  }, [
    filteredComponentIds,
    filteredGroupIds,
    filterBy,
    filterByRNTypes,
    fromDateReleaseNotes
  ]);

  return (
    <ThemeProvider theme={theme}>
      <StatuspageAppBar onAdminPage={false} />
      <FilterBar
        components={components}
        groups={groups}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        onReleaseNotePage={tabId !== 0}
      ></FilterBar>
      <Grid container>
        <Grid item xs={0} lg={1}></Grid>
        <Grid item xs>
          <MainContent>
            <Tabs
              tabId={tabId}
              handleChange={(event: any, newValue: number) =>
                setTabId(newValue)
              }
            ></Tabs>
            {tabId === 0 ? (
              <IncidentPage
                components={components.filter((c) => !c.release_notes_only)}
                incidents={incidents}
                scheduledMaintenances={scheduledMaintenances}
              ></IncidentPage>
            ) : (
              <ReleaseNotePage
                filterByRNTypes={filterByRNTypes}
                setFilterByRNTypes={setFilterByRNTypes}
                releaseNotes={releaseNotes}
                fromDateReleaseNotes={fromDateReleaseNotes}
                setFromDateReleaseNotes={setFromDateReleaseNotes}
              ></ReleaseNotePage>
            )}
          </MainContent>
        </Grid>
        <Grid item xs={0} lg={1}></Grid>
      </Grid>
      <StickyFooter></StickyFooter>
    </ThemeProvider>
  );
}

export default HomePage;
