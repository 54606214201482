import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText
} from '@mui/material';
import React from 'react';
import {
  iconFromIncidentStatus,
  iconFromComponentStatus,
  iconFromReleaseNoteType
} from '../../../StatusIcon';
import styled from 'styled-components';

interface IStatusSelect {
  setStatus: React.Dispatch<React.SetStateAction<number>>;
  status: number;
  iconType: IconType;
}

export enum IconType {
  IncidentStatus,
  ReleaseNoteType,
  ComponentStatus
}

const statusArrayComponentStatus = [
  {
    id: -1,
    string: 'unknown'
  },
  {
    id: 1,
    string: 'operational'
  },
  {
    id: 2,
    string: 'performance issues'
  },
  {
    id: 3,
    string: 'partial outage'
  },
  {
    id: 4,
    string: 'major outage'
  }
];

const statusArrayReleaseNoteType = [
  { id: -1, string: 'unknown' },
  { id: 1, string: 'new release' },
  { id: 2, string: 'changes' },
  { id: 3, string: 'known issue' },
  { id: 4, string: 'bugfixes' }
];

const statusArrayIncidentStatus = [
  {
    id: 0,
    string: 'scheduled'
  },
  {
    id: 1,
    string: 'investigating'
  },
  {
    id: 2,
    string: 'identified'
  },
  {
    id: 3,
    string: 'watching'
  },
  {
    id: 4,
    string: 'fixed'
  }
];

const StatusSelect = ({ setStatus, status, iconType }: IStatusSelect) => {
  const handleStatusSelect = (value: number | string) => {
    if (typeof value === 'number') {
      setStatus(value);
    } else {
      setStatus(parseInt(value));
    }
  };

  let statusArray;
  let iconFn: any;
  switch (iconType) {
    case IconType.IncidentStatus:
      statusArray = statusArrayIncidentStatus;
      iconFn = iconFromIncidentStatus;
      break;
    case IconType.ComponentStatus:
      statusArray = statusArrayComponentStatus;
      iconFn = iconFromComponentStatus;
      break;
    case IconType.ReleaseNoteType:
      statusArray = statusArrayReleaseNoteType;
      iconFn = iconFromReleaseNoteType;
      break;
    default:
      statusArray = statusArrayIncidentStatus;
      iconFn = iconFromIncidentStatus;
      break;
  }

  return (
    <FormControl
      sx={{
        marginTop: '20px',
        color: '#b7b7b7'
        // If I change color of svg, all svgs are greyed out
      }}
      fullWidth
      size="small"
    >
      <InputLabel
        id="statusLabel"
        sx={{
          color: '#b7b7b7'
        }}
      >
        Select status*
      </InputLabel>
      <Select
        labelId="statusLabel"
        id="status"
        value={status}
        onChange={(event) => handleStatusSelect(event.target.value)}
        input={
          <OutlinedInput
            label="Tag"
            sx={{
              color: 'white'
            }}
          />
        }
      >
        {statusArray.map((s: any) => (
          <MenuItem key={s.id} value={s.id}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledIncidentIconContainer>
                {iconFn(s.id)}
              </StyledIncidentIconContainer>
              <ListItemText primary={s.string} />
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusSelect;

const StyledIncidentIconContainer = styled.div`
  background: #555555;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-right: 20px;
`;
