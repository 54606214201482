import ConstructionIcon from '@mui/icons-material/Construction';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styled from 'styled-components';

const StyledStatusImgScaled = styled.img`
  scale: 130%;
`;

export function getIncidentStatus(incident: {
  incident_updates: Array<{ status: number }>;
  status: number;
}): number {
  return incident.incident_updates && incident.incident_updates.length
    ? incident.incident_updates[0].status
    : incident.status;
}

export const iconFromIncidentStatus = (status: number) => {
  switch (status) {
    case 0:
      return <ConstructionIcon sx={{ color: '#ff9900' }}></ConstructionIcon>; // scheduled
    case 1:
      return <SearchIcon sx={{ color: '#ffce00' }}></SearchIcon>; // investigating
    case 2:
      return (
        <StyledStatusImgScaled
          src={
            process.env.PUBLIC_URL +
            '/assets/icon/standard/error-notification.svg'
          }
          alt="identified"
          width="24px"
          height="24px"
        />
      ); // identified
    case 3:
      return <VisibilityIcon sx={{ color: '#7295c2' }}></VisibilityIcon>; // watching
    case 4:
      return (
        <StyledStatusImgScaled
          src={process.env.PUBLIC_URL + '/assets/icon/standard/active.svg'}
          alt="fixed"
          width="24px"
          height="24px"
        />
      ); // fixed
    default:
      return <QuestionMarkIcon></QuestionMarkIcon>;
  }
};

export const iconFromComponentStatus = (
  status: number,
  fontSize: 'medium' | 'large' = 'medium'
) => {
  let fontSizeToPx: string = '';
  switch (fontSize) {
    case 'large':
      fontSizeToPx = '35';
      break;
    case 'medium':
    default:
      fontSizeToPx = '24';
  }

  switch (status) {
    case 1:
      return (
        <StyledStatusImgScaled
          src={process.env.PUBLIC_URL + '/assets/icon/standard/active.svg'}
          alt="operational"
          width={fontSizeToPx + 'px'}
          height={fontSizeToPx + 'px'}
        />
      ); // operational
    case 2:
      return (
        <ErrorOutlineIcon
          fontSize={fontSize}
          sx={{ color: '#7295c2' }}
        ></ErrorOutlineIcon>
      ); // performance issues
    case 3:
      return (
        <ErrorOutlineIcon
          fontSize={fontSize}
          sx={{ color: '#ff9900' }}
        ></ErrorOutlineIcon>
      ); // partial outage
    case 4:
      return (
        <StyledStatusImgScaled
          src={
            process.env.PUBLIC_URL +
            '/assets/icon/standard/error-notification.svg'
          }
          alt="major outage"
          width={fontSizeToPx + 'px'}
          height={fontSizeToPx + 'px'}
        />
      ); // major outage
    default:
      return <></>;
  }
};

export const iconFromReleaseNoteType = (type: number, size: number = 40) => {
  switch (type) {
    case 1:
      return (
        <img
          src={process.env.PUBLIC_URL + '/assets/icon/standard/app-finder.svg'}
          alt="fixed"
          width={size + 'px'}
          height={size + 'px'}
        />
      ); // new feature
    case 2:
      return (
        <img
          src={process.env.PUBLIC_URL + '/assets/icon/standard/refresh.svg'}
          alt="fixed"
          width={size + 'px'}
          height={size + 'px'}
        />
      ); // changes
    case 3:
      return (
        <img
          src={
            process.env.PUBLIC_URL +
            '/assets/icon/standard/configuration-error.svg'
          }
          alt="fixed"
          width={size + 'px'}
          height={size + 'px'}
        />
      ); // known issue
    case 4:
      return (
        <img
          src={process.env.PUBLIC_URL + '/assets/icon/standard/crosshair.svg'}
          alt="fixed"
          width={size + 'px'}
          height={size + 'px'}
        />
      ); // bugfixes
    default:
      return <></>;
  }
};

export const colorFromReleaseNoteType = (type: number) => {
  switch (type) {
    case 1:
      return '#FF9900';
    case 2:
      return '#A343CA';
    case 3:
      return '#0093E2';
    case 4:
      return '#67B74C';
    default:
      return '#ffffff';
  }
};
