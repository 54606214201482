import {
  Grid,
  Button,
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminSectionHeader from '../components/Admin/AdminSectionHeader';
import IncidentUpdateForm from '../components/Admin/Forms/IncidentUpdateForm';
import { useContext, useState } from 'react';
import EditModal from '../components/Admin/EditModal';
import styled from 'styled-components';
import {
  getIncidentStatus,
  iconFromIncidentStatus
} from '../components/StatusIcon';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import ReactMarkdown from 'react-markdown';
import { collapseLinebreaks } from '../utils';
import SnackbarContext from '../contexts/SnackbarContext';
import IncidentForm from '../components/Admin/Forms/IncidentForm';
import { IComponent, IComponentGroup } from '../interfaces';

interface IAdminIncidentPage {
  incidents: Array<any>;
  fetchIncidents: any;
  allIncidentsLoaded: boolean;
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
}

const AdminIncidentPage = ({
  incidents,
  fetchIncidents,
  allIncidentsLoaded,
  components,
  componentGroups
}: IAdminIncidentPage) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<any>(null);
  const { handleResponse } = useContext(SnackbarContext);

  const handleAddIncident = () => {
    setFormContent(
      <IncidentForm
        setModalOpen={setModalOpen}
        fetchIncidents={fetchIncidents}
        components={components}
        componentGroups={componentGroups}
      />
    );
    setModalOpen(true);
  };

  const handleAddIncidentUpdate = (incident: any) => {
    setFormContent(
      <IncidentUpdateForm
        setModalOpen={setModalOpen}
        fetchIncidents={fetchIncidents}
        incident={incident}
      />
    );
    setModalOpen(true);
  };

  const handleEditIncidentUpdate = (incident: any, update: any) => {
    setFormContent(
      <IncidentUpdateForm
        setModalOpen={setModalOpen}
        fetchIncidents={fetchIncidents}
        incident={incident}
        update={update}
      />
    );
    setModalOpen(true);
  };

  const handleDeleteIncidentUpdateCallback = () => {
    setModalOpen(false);
    fetchIncidents();
  };

  const handleDeleteIncidentUpdate = async (update_id: string) => {
    try {
      const response = await fetch('/api/v1/incidents/updates/' + update_id, {
        method: 'DELETE',
        headers: AuthenticationService.instance.headers
      });

      handleResponse(
        response,
        'incident update',
        'delete',
        handleDeleteIncidentUpdateCallback
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <EditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        content={formContent}
      />
      <AdminSectionHeader
        title="Incidents"
        handleClick={() => handleAddIncident()}
        buttonText="Add new incident"
      ></AdminSectionHeader>
      {incidents.map((i) => {
        return (
          <div
            key={i.id}
            style={{
              marginBottom: '20px',
              display: 'flex',
              width: '100%',
              maxWidth: '100%'
            }}
          >
            <StyledIncidentIconContainer>
              {iconFromIncidentStatus(getIncidentStatus(i))}
            </StyledIncidentIconContainer>
            <Accordion
              sx={{
                backgroundColor: '#414141',
                marginBottom: '20px',
                color: 'white',
                width: '100%',
                maxWidth: '100%'
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="incident-content"
                id="incident"
              >
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginBottom: '5px'
                    }}
                  >
                    <Typography variant="h5" component="div">
                      {i.name}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ marginRight: '10px' }}
                    >
                      {new Date(i.created_at).toLocaleString()}
                    </Typography>
                  </div>
                  <div>
                    <Grid container>
                      {(i.groups ?? []).concat(i.components).map((c: any) => {
                        return (
                          <Chip
                            label={c.name}
                            sx={{
                              border: '#7295C2',
                              background: '#7295C280',
                              color: '#ffffff',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                              marginRight: '20px',
                              marginBottom: '10px'
                            }}
                            key={c.id}
                          />
                        );
                      })}
                    </Grid>
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    <ReactMarkdown>
                      {collapseLinebreaks(i.message)}
                    </ReactMarkdown>
                  </div>
                </div>
              </AccordionSummary>
              <Divider
                sx={{
                  bgcolor: 'white',
                  marginLeft: '10px',
                  marginRight: '10px'
                }}
              />
              <AccordionDetails sx={{ marginTop: '0px' }}>
                <AdminSectionHeader
                  title="Updates"
                  buttonText="Add new update"
                  handleClick={() => handleAddIncidentUpdate(i)}
                />

                {i.incident_updates.map((u: any) => (
                  <div key={u.id}>
                    <Divider
                      sx={{
                        bgcolor: '#b7b7b7',
                        marginBottom: '10px',
                        marginLeft: '10px',
                        marginRight: '10px'
                      }}
                    />
                    <Grid container>
                      <Grid item>
                        <StyledIncidentIconContainer>
                          {iconFromIncidentStatus(u.status)}
                        </StyledIncidentIconContainer>
                      </Grid>
                      <Grid item xs>
                        <div>
                          <Typography variant="h6">
                            {new Date(u.created_at).toLocaleString()}
                          </Typography>

                          <ReactMarkdown>
                            {collapseLinebreaks(u.message)}
                          </ReactMarkdown>
                        </div>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          sx={{ marginRight: '10px' }}
                          onClick={() => handleEditIncidentUpdate(i, u)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ marginRight: '20px' }}
                          onClick={() => handleDeleteIncidentUpdate(u.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      <div>
        {!allIncidentsLoaded ? (
          <CircularProgress
            sx={{
              margin: 'auto',
              display: 'block',
              color: '#ff9900 !important',
              scale: 0.1
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};

export default AdminIncidentPage;

const StyledIncidentIconContainer = styled.div`
  background: #555555;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-right: 20px;
`;

const Container = styled.div`
  overflow: hidden;
`;
