import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import styled from 'styled-components';
import useValidation from '../../../hooks/useValidation';
import { useContext, useState } from 'react';
import { IComponent, IComponentGroup } from '../../../interfaces';
import { componentSelectTitle } from '../../../utils';
import StatusSelect, { IconType } from './FormComponents/StatusSelect';
import LoadingEditButton from './FormComponents/LoadingEditButton';
import ExpandableTextbox from './FormComponents/ExpandableTextbox';
import { AuthenticationService } from '../../../services/Authentication/AuthenticationService';
import { v4 as uuidv4 } from 'uuid';
import SnackbarContext from '../../../contexts/SnackbarContext';

interface IIncidentForm {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchIncidents: any;
  components: Array<IComponent>;
  componentGroups: Array<IComponentGroup>;
}

const IncidentForm = ({
  setModalOpen,
  fetchIncidents,
  components,
  componentGroups
}: IIncidentForm) => {
  const validateMessage = (message: string | null) => {
    return !!message;
  };

  const [name, setName] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [status, setStatus] = useState<number>(0);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const [component_ids, setComponent_ids] = useState<string[]>([]);
  const [group_ids, setGroup_ids] = useState<string[]>([]);

  const handleComponentSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setComponent_ids(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGroupSelect = (event: any) => {
    const {
      target: { value }
    } = event;
    setGroup_ids(typeof value === 'string' ? value.split(',') : value);
  };

  const { setSnackMessage, setSnackSeverity, setSnackOpen, handleResponse } =
    useContext(SnackbarContext);

  const responseCallback = () => {
    setModalOpen(false);
    fetchIncidents();
  };

  const [nameValid, showNameValid, updateNameDisplay] = useValidation(
    validateMessage,
    name
  );
  const [messageValid] = useValidation(validateMessage, message);

  const handleAddIncident = async () => {
    if (messageValid && nameValid) {
      setLoadingEdit(true);
      try {
        const response = await fetch('/api/v1/incidents/', {
          method: 'POST',
          headers: AuthenticationService.instance.headers,
          body: JSON.stringify({
            id: uuidv4(),
            name,
            visible: true,
            status,
            message,
            component_status: status,
            notify: true,
            created_at: new Date(),
            updated_at: null,
            component_ids,
            group_ids
          })
        });
        setLoadingEdit(false);

        handleResponse(response, 'incident', 'add', responseCallback);
      } catch (e) {
        console.error(e);
        setLoadingEdit(false);
      }
    } else {
      setSnackMessage('The form contains errors');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  };

  return (
    <div style={styles.layout}>
      <div>
        <Typography variant="h5" component="div">
          Add incident
        </Typography>
      </div>

      <div>
        <TextFieldStyled
          {...(showNameValid
            ? { error: false }
            : { error: true, helperText: 'This field is required' })}
          onBlur={() => updateNameDisplay()}
          label="Name*"
          id="name"
          size="small"
          defaultValue={''}
          fullWidth
          InputProps={{ style: { color: 'white' } }}
          onChange={(e: any) => setName(e.currentTarget.value)}
          style={{ marginTop: '20px' }}
        />
        <ExpandableTextbox
          defaultMessage={''}
          message={message ?? ''}
          setMessage={setMessage}
        />
        <FormControl
          sx={{ marginTop: '20px', color: '#b7b7b7' }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="groupsSelectLabel"
            sx={{
              color: '#b7b7b7',
              borderColor: '#b7b7b7'
            }}
          >
            Select affected groups
          </InputLabel>
          <Select
            labelId="groupsSelectLabel"
            id="groupsSelect"
            multiple
            value={group_ids}
            onChange={(event) => handleGroupSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value) => (
                  <Chip
                    label={
                      componentGroups.filter((group) => group.id === value)[0]
                        .name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {componentGroups.map((g) => (
              <MenuItem key={g.id} value={g.id}>
                <Checkbox checked={group_ids.indexOf(g.id) > -1} />
                <ListItemText primary={g.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ marginTop: '20px', color: '#b7b7b7' }}
          fullWidth
          size="small"
        >
          <InputLabel
            id="componentsSelectLabel"
            sx={{
              color: '#b7b7b7',
              borderColor: '#b7b7b7'
            }}
          >
            Select affected components
          </InputLabel>
          <Select
            labelId="componentsSelectLabel"
            id="componentsSelect"
            multiple
            value={component_ids}
            onChange={(event) => handleComponentSelect(event)}
            input={<OutlinedInput label="Tag" sx={{ color: 'white' }} />}
            renderValue={(selected) => (
              <div style={{ display: 'flex' }}>
                {selected.map((value) => (
                  <Chip
                    label={
                      components.filter(
                        (component: any) => component.id === value
                      )[0].name
                    }
                    key={value}
                    sx={{
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginRight: '10px'
                    }}
                  />
                ))}
              </div>
            )}
          >
            {components.map((c: any) => (
              <MenuItem key={c.id} value={c.id}>
                <Checkbox checked={component_ids.indexOf(c.id) > -1} />
                <ListItemText
                  primary={componentSelectTitle(c, componentGroups)}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <StatusSelect
          iconType={IconType.IncidentStatus}
          setStatus={setStatus}
          status={status}
        />

        <Typography variant="body2" color="#b7b7b7" sx={{ marginTop: '8px' }}>
          *required fields
        </Typography>

        <div
          style={{ ...styles.flex, marginTop: '20px', marginBottom: '30px' }}
        >
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <LoadingEditButton
            onClick={() => handleAddIncident()}
            loadingEdit={loadingEdit}
            text={'Add Incident'}
            disabled={!(messageValid && nameValid)}
          ></LoadingEditButton>
        </div>
      </div>
    </div>
  );
};

const styles = {
  layout: {
    marginTop: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#ffffff'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});

export default IncidentForm;
