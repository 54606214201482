import styled from 'styled-components';
import SectionHeader from '../SectionHeader';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import '../../styles/VerticalTimeline.css';
import '../../styles/VerticalTimelineElement.css';
import IncidentCard from '../IncidentCard';
import { getIncidentStatus, iconFromIncidentStatus } from '../StatusIcon';
import { IIncident } from '../../interfaces';

const Container = styled.div``;

interface IIncidentLog {
  incidents: Array<IIncident>;
}

interface IVerticalElement {
  date: string;
  name: string;
  message: string;
  created_at: string;
  scheduledFrom: string;
  scheduledTo: string;
  incident_updates: Array<any>;
}

const VerticalElement = (props: IVerticalElement & any) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: '#414141',
        color: '#fff'
      }}
      iconStyle={{ background: '#555555', color: '#fff' }}
      date={props.date}
      icon={iconFromIncidentStatus(props.status) as any}
    >
      <IncidentCard
        name={props.name}
        message={props.message}
        created_at={props.created_at}
        incident_updates={props.updates}
        components={props.components}
        groups={props.groups}
        scheduledFrom={props.scheduledFrom}
        scheduledTo={props.scheduledTo}
      ></IncidentCard>
    </VerticalTimelineElement>
  );
};

const IncidentLog: React.FC<IIncidentLog> = ({ incidents }: IIncidentLog) => {
  return (
    <div>
      <Container>
        <SectionHeader title="Incident Log"></SectionHeader>
        {incidents && incidents.length ? (
          <VerticalTimeline>
            {incidents
              .filter((i) => {
                const status = getIncidentStatus(i);
                return status !== 0;
              })
              .map((i) => {
                return (
                  <VerticalElement
                    date={new Date(i.created_at).toLocaleString()}
                    name={i.name}
                    status={getIncidentStatus(i)}
                    message={i.message}
                    created_at={i.created_at}
                    updates={i.incident_updates}
                    components={i.components}
                    groups={i.groups}
                    scheduledFrom={i.scheduled_maintenance?.scheduledFrom}
                    scheduledTo={i.scheduled_maintenance?.scheduledTo}
                    key={i.id}
                  ></VerticalElement>
                );
              })}
          </VerticalTimeline>
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
};

export default IncidentLog;
