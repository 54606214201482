import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Grid,
  Typography,
  AppBar,
  Card,
  CardContent,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  FormControl
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../services/Authentication/AuthenticationService';
import { APP_VERSION } from '../constants';
import StatuspageAppBar from '../components/StatuspageAppBar';
import SnackbarContext from '../contexts/SnackbarContext';

const LoginPage = () => {
  const authService = AuthenticationService.instance;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  let navigate = useNavigate();
  const { setSnackMessage, setSnackSeverity, setSnackOpen } =
    useContext(SnackbarContext);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const success = await authService.login(email, password);
      if (success) navigate('/admin');
      else {
        setSnackMessage('Wrong login data');
        setSnackSeverity('error');
        setSnackOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackMessage('Wrong login data');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
    setLoading(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StatuspageAppBar onAdminPage={false} />
      <Grid container>
        <Grid item xs={0} lg={1}></Grid>
        <Grid item xs>
          <div style={{ marginTop: '20px' }}>
            <MainContent>
              <Box sx={styles.center}>
                <CardStyled boxshadow="true">
                  <CardContentStyled>
                    <Typography variant="h5" component="div">
                      Login
                    </Typography>
                    <FormControl fullWidth>
                      <TextFieldStyled
                        label="E-mail"
                        id="email"
                        size="small"
                        type="email"
                        InputProps={{ style: { color: 'white' } }}
                        sx={{ marginTop: '20px' }}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                      <TextFieldStyled
                        label="Password"
                        id="password"
                        size="small"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        InputProps={{ style: { color: 'white' } }}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        onKeyDown={handleKeyDown}
                        sx={{ marginTop: '20px' }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                    <LoadingButton
                      variant="contained"
                      onClick={() => handleLogin()}
                      loading={loading}
                      sx={{ marginTop: '20px' }}
                    >
                      Login
                    </LoadingButton>
                  </CardContentStyled>
                </CardStyled>
              </Box>
            </MainContent>
            <AppBar position="fixed" color="secondary" sx={styles.footer}>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '10px',
                  padding: '5px',
                  loading: { bgcolor: '#b7b7b7' }
                }}
                color="#b7b7b7"
              >
                Version {APP_VERSION}
              </Typography>
            </AppBar>
          </div>
        </Grid>

        <Grid item xs={0} lg={1}></Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;

const MainContent = styled.div`
  color: #ffffff;
  padding: 0px 30px;
  overflow: auto;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9900'
    },
    secondary: {
      main: '#333333'
    },
    background: {
      default: '#222222'
    }
  }
});

const CardStyled = styled(Card)<any>`
  box-shadow: ${({ boxshadow }) =>
    boxshadow
      ? '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important'
      : 'none !important'};
  border-radius: 0 !important;
  margin-bottom: 20px;
  width: 100%;
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #414141;
  ${({ padding }) => ('padding: ' + padding ? padding : '0px !important')};
  color: #ffffff;
`;

const styles = {
  center: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '40%',
    height: 'fit-content',
    backgroundColor: '#333333',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto'
  },
  footer: {
    bottom: 0,
    top: 'auto'
  }
};

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});
