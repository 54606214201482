//import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import MatTabs from '@mui/material/Tabs';

interface ITabs {
  tabId: number;
  handleChange: any;
}

// TODO Divider throws warnings

const AdminTabs = ({ tabId, handleChange }: ITabs) => {
  return (
    <MatTabs
      value={tabId}
      onChange={handleChange}
      textColor="inherit"
      indicatorColor="primary"
    >
      <Tab label="Incidents" />
      {/*<Divider orientation="vertical" variant="middle" flexItem></Divider>*/}
      <Tab label="Components" />
      <Tab label="Component Groups" />
      <Tab label="Scheduled Maintenances" />
      <Tab label="Release Notes" />
      <Tab label="Admin Users" />
    </MatTabs>
  );
};

export default AdminTabs;
