import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';
import {
  IComponent,
  IComponentGroup,
  IImage,
  IImagePart
} from '../../interfaces';
import ReactMarkdown from 'react-markdown';
import {
  iconFromReleaseNoteType,
  colorFromReleaseNoteType
} from '../StatusIcon';
import { collapseLinebreaks } from '../../utils';
import Carousel from 'react-material-ui-carousel';
import { useState, useEffect, useMemo } from 'react';
import FullscreenImage from './FullscreenImage';

const CardTagsContainer = styled.div`
  margin: 20px 0;
  padding: 0 16px;
`;

const CardMessageContainer = styled.div`
  margin: 20px 0;
  padding: 0 16px;
`;

const CardImageContainer = styled.div`
  background: #666666;
  padding: 20px;
`;

const CardImageTextContainer = styled.div`
  padding: 0 20px;
`;

const CardFooterContainer = styled.div`
  margin-top: 20px;
  color: #ffffffb3;
  padding: 0 16px 16px 0;
`;

const CardStyled = styled(Card)<any>`
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  margin: 20px;
  padding: 0 !important;
  border-left: 8px solid ${({ color }) => color ?? '#ffffff'};
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #555555;
  color: #ffffff;
  padding: 0 !important;
  &:last-child {
    padding-bottom: 0;
  }
`;

const ImageLoadingContainer = styled.div`
  text-align: center;
`;

const CarouselIcon = styled.img`
  opacity: 0.5;
  &:hover {
    opacity: 1 !important;
  }
`;

export interface IReleaseNoteCard {
  name: string;
  type: number;
  message: string;
  version_tag: string;
  components: Array<IComponent>;
  groups: Array<IComponentGroup>;
  images: Array<IImagePart>;
  created_at?: string;
}

interface IImageItem {
  id: string;
  message: string;
  base64encoding: string;
}

const ReleaseNoteCard = ({
  name,
  type,
  message,
  version_tag,
  created_at,
  components,
  groups,
  images
}: IReleaseNoteCard) => {
  const [expand, setExpand] = useState(false);
  const [imagesWithEncoding, setImagesWithEncoding] = useState<Array<IImage>>(
    images
      ? images.map((i) => {
          return {
            ...i,
            base64encoding: ''
          };
        })
      : []
  );
  // this is required to correctly render the image: https://github.com/Learus/react-material-ui-carousel/issues/189

  useEffect(() => {
    const loadImage = async (id: string) => {
      const image = await fetch(`/api/v1/images/${id}`);
      return (await image.json()) as IImage;
    };

    for (const image of images) {
      loadImage(image.id)
        .then((data) => {
          const newImages = [...imagesWithEncoding];
          const idx = newImages.findIndex((i) => i.id === image.id);
          newImages[idx].base64encoding = data.base64encoding;
          setImagesWithEncoding(newImages);
        })
        .catch((error) => {
          console.error(`Could not load image ${image.id}`);
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, []);

  const imagesLoading = useMemo(
    () =>
      imagesWithEncoding.filter((i) => i.base64encoding).length < images.length,
    [images, imagesWithEncoding]
  );

  const ImageItem = ({ id, message, base64encoding }: IImageItem) => {
    return (
      <div>
        <Grid container>
          <Grid item>
            <FullscreenImage
              alt="Release Note"
              id={id}
              base64encoding={base64encoding}
            ></FullscreenImage>
          </Grid>
          <Grid item>
            <CardImageTextContainer>
              <ReactMarkdown className="react-markdown">
                {collapseLinebreaks(message)}
              </ReactMarkdown>
            </CardImageTextContainer>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <CardStyled color={colorFromReleaseNoteType(type)}>
      <CardContentStyled>
        <Grid
          container
          spacing={1}
          sx={{ padding: '16px 16px 0 16px' }}
          alignItems={'center'}
        >
          <Grid item>{iconFromReleaseNoteType(type)}</Grid>
          <Grid item>
            <Typography variant="h5" component="div" fontSize={'20px'}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <Typography variant="h5" component="div" fontSize={'20px'}>
              {version_tag}
            </Typography>
          </Grid>
        </Grid>
        <CardTagsContainer>
          <Grid container spacing={1}>
            {(groups ?? [])
              .concat((components ?? []) as Array<any>)
              .map((c) => {
                return (
                  <Grid key={c.id + Math.random()} item>
                    <Chip
                      label={c.name}
                      sx={{
                        maxWidth: '200px',
                        border: '#7295C2',
                        background: '#7295C280',
                        color: '#ffffff',
                        borderStyle: 'solid',
                        borderWidth: '1px'
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </CardTagsContainer>
        <CardMessageContainer>
          <ReactMarkdown className="react-markdown">
            {collapseLinebreaks(message)}
          </ReactMarkdown>
        </CardMessageContainer>

        <Collapse in={expand}>
          <CardImageContainer>
            {imagesLoading && (
              <ImageLoadingContainer>
                <CircularProgress
                  sx={{
                    margin: 'auto',
                    display: 'block',
                    color: '#ff9900 !important',
                    scale: 0.1
                  }}
                />
                <h2>Images are loading...</h2>
              </ImageLoadingContainer>
            )}

            <Carousel
              autoPlay={false}
              swipe={false}
              NextIcon={
                images.length > 1 ? <CarouselIcon
                  src={
                    process.env.PUBLIC_URL + '/assets/icon/standard/forward.svg'
                  }
                  alt="fixed"
                  width={40 + 'px'}
                  height={40 + 'px'}
                /> : <></>
              }
              navButtonsAlwaysVisible={true}
              PrevIcon={
                images.length > 1 ? <CarouselIcon
                  src={
                    process.env.PUBLIC_URL + '/assets/icon/standard/back.svg'
                  }
                  alt="fixed"
                  width={40 + 'px'}
                  height={40 + 'px'}
                /> : <></>
              }
              navButtonsProps={{
                style: {
                  backgroundColor: '#00000000'
                }
              }}
            >
              {!imagesLoading &&
                imagesWithEncoding?.map((i, idx) => (
                  <ImageItem
                    key={idx}
                    message={i.message}
                    id={i.id}
                    base64encoding={i.base64encoding}
                  ></ImageItem>
                ))}
            </Carousel>
          </CardImageContainer>
        </Collapse>
        {images?.length ? (
          <Grid container justifyContent="center" sx={{ marginTop: '8px' }}>
            <IconButton
              sx={{ color: '#ffffff' }}
              onClick={() => setExpand(!expand)}
              size="large"
            >
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
        <CardFooterContainer>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item sx={{ fontSize: '14px' }}>
              {created_at ? new Date(created_at).toLocaleString() : ''}
            </Grid>
          </Grid>
        </CardFooterContainer>
      </CardContentStyled>
    </CardStyled>
  );
};

export default ReleaseNoteCard;
