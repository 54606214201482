import { IComponentGroup, IComponent } from '../interfaces';

export const componentSelectTitle = (
  component: IComponent,
  componentGroups: Array<IComponentGroup>
): string => {
  let title = component.name;
  if (!component.groups?.length) return component.name;
  let groupsByLevel = [] as Array<{ id: string; name: string; level: number }>;
  let groupsToVerify = [
    ...component.groups.map((g) => {
      return {
        ...g,
        level: 1
      };
    })
  ];
  while (groupsToVerify.length) {
    const group = groupsToVerify.pop();
    if (!group) break;
    if (!groupsByLevel.find((g) => g.id === group.id)) {
      groupsByLevel.push({
        id: group.id,
        name: group.name,
        level: group.level
      });
      const groupFromComponentGroups = componentGroups.find(
        (g) => g.id === group.id
      );
      if (groupFromComponentGroups?.supergroup) {
        groupsToVerify.push({
          id: groupFromComponentGroups.supergroup.id,
          name: groupFromComponentGroups.supergroup.name,
          level: group.level + 1
        });
      }
    }
    groupsByLevel = groupsByLevel.sort((a, b) => a.level - b.level); // sort ascending, always triggering in order of levels
  }
  groupsByLevel = groupsByLevel.sort((a, b) => b.level - a.level); // sort descending for final text

  let currentLevel = groupsByLevel[0].level ?? 0;
  let groupNames = [] as Array<string>;
  let groupNamesCurrentLevel = [] as Array<string>;
  for (let group of groupsByLevel) {
    if (group.level !== currentLevel) {
      currentLevel = group.level;
      groupNames.push(groupNamesCurrentLevel.join(', '));
      groupNamesCurrentLevel = [];
      groupNamesCurrentLevel.push(group.name);
    } else {
      groupNamesCurrentLevel.push(group.name);
    }
  }
  groupNames.push(groupNamesCurrentLevel.join(', '));

  title = `${title} (${groupNames.join(' - ')})`;
  return title;
};

// collapse all line breaks (no matter the length) into \n\n
// which is shown as one linebreak in Markdown
export function collapseLinebreaks(message: string) {
  return message.replaceAll(/(\n)+/g, '\n\n');
}
