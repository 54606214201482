import { IReleaseNote } from '../interfaces';
import styled from 'styled-components';
import ReleaseNoteCard from '../components/ReleaseNoteCard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Chip, Grid, TextField } from '@mui/material';
import { iconFromReleaseNoteType } from '../components/StatusIcon';
import SectionHeader from '../components/SectionHeader';

const Container = styled.div`
  min-height: 1200px;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-left: 20px;
`;

const DatePickerContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b7b7b7'
    },
    '&:hover fieldset': {
      borderColor: '#ff9900'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#b7b7b7'
  }
});

interface IReleaseNotePage {
  filterByRNTypes: Array<number>;
  setFilterByRNTypes: (val: Array<number>) => void;
  releaseNotes: Array<IReleaseNote>;
  fromDateReleaseNotes: Date;
  setFromDateReleaseNotes: (val: Date) => void;
}

const statusArrayReleaseNoteType = [
  { id: 1, name: 'new release' },
  { id: 2, name: 'changes' },
  { id: 3, name: 'known issue' },
  { id: 4, name: 'bugfixes' }
];

const ReleaseNotePage = ({
  releaseNotes,
  filterByRNTypes,
  setFilterByRNTypes,
  fromDateReleaseNotes,
  setFromDateReleaseNotes
}: IReleaseNotePage) => {
  function selectFilterByRNType(rnType: number) {
    if (filterByRNTypes.includes(rnType)) {
      setFilterByRNTypes(filterByRNTypes.filter((t) => t !== rnType));
    } else {
      setFilterByRNTypes(filterByRNTypes.concat([rnType]));
    }
  }

  return (
    <div>
      <SectionHeader title="Release Notes" />
      <Grid container>
        <Grid item xs={0} lg={2}></Grid>
        <Grid item xs={12} lg={8}>
          <Container>
            <FiltersContainer>
              {statusArrayReleaseNoteType.map((rnt) => {
                return (
                  <p key={rnt.id}>
                    <Chip
                      clickable
                      label={rnt.name}
                      onClick={() => selectFilterByRNType(rnt.id)}
                      icon={iconFromReleaseNoteType(rnt.id, 24)}
                      sx={{
                        border: '#7295C2',
                        marginRight: '20px',
                        ...(filterByRNTypes.includes(rnt.id)
                          ? { background: '#7295C280' }
                          : {}),
                        color: '#ffffff',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        padding: '10px 0px'
                      }}
                    />
                  </p>
                );
              })}
              <DatePickerContainer>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={deLocale}
                >
                  <DatePicker
                    label="Starting from"
                    value={fromDateReleaseNotes}
                    onChange={(newDate) => {
                      if (newDate) setFromDateReleaseNotes(newDate);
                    }}
                    InputProps={{ style: { color: 'white' } }}
                    renderInput={(InputProps) => (
                      <TextFieldStyled
                        {...InputProps}
                        size="small"
                        style={{ marginTop: '20px' }}
                        sx={{
                          svg: { color: '#b7b7b7' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </DatePickerContainer>
            </FiltersContainer>
            {releaseNotes?.length ? (
              releaseNotes.map((rn) => {
                return (
                  <ReleaseNoteCard
                    key={rn.id}
                    name={rn.name}
                    type={rn.type}
                    message={rn.message}
                    components={rn.components}
                    groups={rn.groups}
                    images={rn.images}
                    created_at={rn.created_at}
                    version_tag={rn.version_tag}
                  />
                );
              })
            ) : (
              <></>
            )}
          </Container>
        </Grid>
        <Grid item xs={0} lg={2}></Grid>
      </Grid>
    </div>
  );
};

export default ReleaseNotePage;
